import { InputAdornment, ListSubheader, MenuItem, Popover, Select, TextField, Typography } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import clsx from "clsx";
import React, { useMemo, useState } from "react";
import { Arrow as SvgArrow } from "../../icons/";
import useStyles from "./MenuDropDown.style";
const containsText = (name: any, searchText: any) =>
  name?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

const MenuDropDown = (props: any) => {
  const classes = useStyles();
  const { value, options, onChange, disableNotAllowed = false, hideArrow = false, disabled = false } = props;
  const [searchText, setSearchText] = useState<any>("");
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string);
  };
  const displayedOptions = useMemo(
    () => options.map((option: any) => containsText(option?.name, searchText) ? (option) : ({ ...option, hide: true })),
    [searchText, options]
  );
  return (
    <Select
      variant="filled"
      value={value}
      onChange={handleChange}
      className={classes.selectContainer}
      classes={{
        filled: classes.selectStyle,
        icon: classes.arrowIcon,
        select: classes.focusStyle,
        iconFilled: classes.iconStyle
      }}
      displayEmpty
      disableUnderline
      MenuProps={{
        autoFocus: false,
        classes: { paper: classes.paperStyle },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: -10,
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      IconComponent={!hideArrow ? SvgArrow : () => (<></>)}
      disabled={(!disableNotAllowed && options.length === 1) || disabled}
      onClose={() => setSearchText("")}
    >
      <ListSubheader className={classes.subHeader}>
        <TextField
          classes={{ root: classes.textField }}
          size="small"
          variant="outlined"
          autoFocus
          placeholder="Type to search..."
          fullWidth
          InputProps={{
            className: classes.input,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ fill: "#aaa2aa" }} />
              </InputAdornment>
            )
          }}
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
          onClick={(e: any) => e.stopPropagation()}
          onKeyDown={(e: any) => {
            if (e.key !== "Escape") {
              e.stopPropagation();
            }
          }}
        />
      </ListSubheader>
      {displayedOptions &&
        displayedOptions.map((option: any) => {
          return (
            <MenuItem key={option.key} value={option.value} classes={{ selected: classes.selected }} className={clsx(classes.optionStyle, { [classes.hideOption]: option.hide })}>
              <Typography className={classes.optionText}>{option.name}</Typography>
            </MenuItem>
          );
        })}
    </Select>
  );
};

export default MenuDropDown;
