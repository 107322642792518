import Axios from "axios";
import _ from "lodash";

export const downloadAs = (url: string, name: string) => {
    if (!url || !name) {
        return;
    }

    const ext = _.last(url.split("."));
    let fileName = name;
    if (ext && !name.endsWith(ext)) {
        fileName += `.${ext}`;
    }
    Axios.get(url, {
        headers: {
            "Content-Type": "application/octet-stream"
        },
        responseType: "blob"
    })
        .then((response: any) => {
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(response.data);
            a.href = url;
            a.download = fileName;
            a.click();
        })
        .catch((err: any) => {
            console.error("error", err);
        });
};
