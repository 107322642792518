import React, { useState, useCallback } from 'react';
import {
  Dialog,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { t } from 'ttag';
import useStyles from './styles';
import Close from '@icons/Close';
import { Search as SearchIcon } from '@material-ui/icons';
import Button from '@cool_widgets/Button';
import { isEmpty } from 'lodash';

/**
 * NewTemplateDialog component for creating or selecting a template.
 *
 * This component renders a dialog with different input options based on the type of template selection.
 * It supports two modes: creating a new template or selecting from an existing list.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.templateName - The name of the new template (if creating).
 * @param {function} props.setTemplateName - Callback to update the template name.
 * @param {Object} props.templateSelectionType - The type of template selection being performed.
 * @param {string} props.templateSelectionType.type - The type of selection, either "new" or "list".
 * @param {string} props.templateSelectionType.title - The title to display in the dialog.
 * @param {string} props.templateSelectionType.saveText - The title to display in the save button.
 * @param {Object[]} props.templatesList - List of templates available for selection in "list" mode.
 * @param {function} props.handleClose - Callback function to close the dialog.
 * @param {function} props.handleSave - Callback function to save the selected or created template.
 *
 * @returns {JSX.Element} The rendered NewTemplateDialog component.
 */

const NewTemplateDialog = ({
  templateName,
  setTemplateName,
  templateSelectionType,
  templatesList,
  handleClose,
  handleSave,
}) => {
  const classes = useStyles();
  const { type, title, saveText } = templateSelectionType;
  const [selectedTemplate, setSelectedTemplate] = useState('');

  /**
   * Handles the change in the template name input field for the "new" template creation mode.
   *
   * @callback handleTemplateNameChange
   * @param {Object} event - The input change event.
   */
  const handleTemplateNameChange = useCallback((event) => {
    setTemplateName(event.target.value);
  }, []);

  const isSaveDisabled = () => {
    return (
      (type == 'new' && !templateName) ||
      (type == 'list' && isEmpty(selectedTemplate)) ||
      type == 'file'
    );
  };

  const handleSaveChanges = () => {
    handleSave(selectedTemplate.id);
  };

  /**
   * Renders the appropriate content based on the `type` of template selection.
   *
   * If `type` is "new", it renders an input field to create a new template.
   * If `type` is "list", it renders an autocomplete dropdown to select an existing template.
   *
   * @function renderContent
   * @returns {JSX.Element|undefined} The JSX content for the dialog based on the selection type.
   */
  const renderContent = () => {
    switch (type) {
    case 'new':
      return (
        <div className={classes.inputContainer}>
          <Typography
            className={classes.label}
          >{t`Template Name`}</Typography>
          <TextField
            variant="outlined"
            placeholder={t`Template Name`}
            value={templateName}
            onChange={handleTemplateNameChange}
            InputProps={{ classes: { root: classes.inputRoot } }}
          />
        </div>
      );
    case 'list':
      return (
        <div className={classes.inputContainer}>
          <Autocomplete
            fullWidth
            options={Object.values(templatesList)}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setSelectedTemplate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                placeholder={t`Search...`}
                {...params}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ fill: '#aaa2aa' }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
      );
    default:
      return;
    }
  };

  return (
    <Dialog
      disableEnforceFocus
      fullScreen
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      <div className={classes.dialogHeader}>
        <Typography className={classes.headerTitle}>{title}</Typography>
        <IconButton
          disableRipple
          className={classes.iconBtnStyle}
          onClick={handleClose}
        >
          <Close color="#7F7692" />
        </IconButton>
      </div>
      <div id="dialogContent" className={classes.dialogContent}>
        {renderContent()}
      </div>
      <div className={classes.actionsContainer}>
        <Button onClick={handleClose} marginRight white width={150}>
          {t`cancel`}
        </Button>
        <Button
          disabled={isSaveDisabled()}
          onClick={handleSaveChanges}
          width={150}
        >
          {saveText}
        </Button>
      </div>
    </Dialog>
  );
};

export default NewTemplateDialog;
