import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    margin: 'auto',
  },
  view: {
    display: 'flex',
    height: '100%',
    overflowX: 'hidden',
  },
  contentArea: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: 'rgb(236, 235, 239)',
  },
  pageView: {
    paddingLeft: '24px',
    paddingRight: '24px',
    position: 'relative',
  },
  mainContainer: {
    background: '#FFF',
    height: 'calc(100vh - 223px)',
    overflow: 'auto',
  },
  inputContainer: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '45px',
  },
  inputSection: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'left',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
  },
  searchTitle: {
    paddingLeft: 7,
    fontSize: '16px',
    fontWeight: 500,
  },
  tableContainer: {
    overflowY: 'auto',
  },
  tableCell: {
    ...theme.tables.tableCell,
    padding: '9px 16px !important',
    height: '51px',
  },
  tableCellBold: {
    fontWeight: '700',
  },
  nowrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis' 
  },
  tableHeadCell: {
    padding: '0 15px !important',
    ...theme.tables.headerCell,
    height: '45px',
    width: '180px',
    fontWeight: 400,
  },
  tableSmallHeadCell: {
    padding: '0 15px !important',
    ...theme.tables.headerCell,
    height: '52px',
    width: '52px',
  },
  mappbingTableDeleteBtn: {
    padding: 0,
  },
  // Style v2
  bacnetInfoContainer: {
    borderRight: '1px solid #EBEBEB',
  },
  block: {},
  block__header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    minHeight: '72px',
    padding: '20px 36px',
    position: 'sticky',
    top: 0,
    background: '#fff',
    zIndex: 99,
    fontFamily: 'Roboto',
  },
  block__body: {
    padding: '0 20px',
  },
  block__title: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '23.44px',
    marginRight: 'auto',
  },
  block__actions: {
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {},
  backBtnWrapper: {
    marginBottom: '11px',
  },
  backBtn: {
    background: 'transparent',
    outline: 'none',
    border: 'none',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '17.58px',
    padding: '6px 0',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& span': {
      marginLeft: '8px',
    },
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '23.44px',
    marginBottom: '8px',
    fontFamily: 'Roboto',
  },
  ids: {
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '17.58px',
    fontFamily: 'Roboto',
    '& span:first-child': {
      fontWeight: '700',
      marginRight: '8px',
      paddingRight: '8px',
      borderRight: '1px solid #545964',
    },
  },
  allMappedFilter: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '24px',
    '& div': {
      padding: '6px 16px 6px 16px',
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '17.58px',
      border: '1px solid #AAA2AA',
      cursor: 'pointer',
      '&:first-child': {
        borderRadius: '4px 0 0 4px',
        borderRight: 'none',
      },
      '&:last-child': {
        borderRadius: '0px 4px 4px 0',
      },
      '&.IsSelected': {
        background: '#4B1C46',
        color: '#fff',
      },
    },
  },
  refreshBtn: {
    border: 'none',
  },
  pageHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    margin: '0 -24px 24px',
    padding: '16px 24px',
    background: '#fff',
  },
  headerActionBlock: {},
  headerActionBlock__label: {
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '17.58px',
    marginBottom: '2px',
  },
  headerActionBlock__input: {
    fontFamily: 'Roboto',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D5D2D5',
      borderRadius: '4px',
    },
    '& input': {
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '17.58px',
      padding: '10px 16px',
      border: 'none',
    },
  },
  headerActionBlock__templateSrcMenu: {
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '17.58px',
    padding: '10px 16px',
    border: '1px solid #D5D2D5',
    borderRadius: '4px',
    width: '230px',
    justifyContent: 'left',
    position: 'relative',
    '& .Name': {
      marginRight: 'auto',
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '17.58px',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '45px',
    },
    '& .VLine': {
      position: 'absolute',
      height: '100%',
      width: '1px',
      background: '#D5D2D5',
      right: '45px',
    },
    '& svg': {
      position: 'absolute',
      right: '3px',
    },
  },
  searchField: {
    '& svg': {
      transform: 'scale(0.8) translate(-10px,0)',
    },
  },
}));

export default useStyles;
