import React, { useState, useCallback, useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  IconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { t as translate } from 'ttag';

import ButtonV2 from '@cool_widgets/ButtonV2';

const useStyles = makeStyles((theme) => ({
  // Dialog styles
  dialogTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '18.75px',
    textAlign: 'left',
    padding: '19px 24px',
    backgroundColor: '#4B1C46',
    borderBottom: '1px solid #D5D2D5',
    color: theme.palette.common.white,
    '& h2': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '18.75px',
      margin: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#fff',
  },
  dialogContent: {
    padding: '30px',
    width: '400px',
  },
  dialogActions: {
    borderTop: '1px solid #e0e0e0',
    padding: '16px 24px',
    backgroundColor: '#F6F5F6',
  },
}));

const ConfirmationDialog = ({ open, onClose, templateName }) => {
  const classes = useStyles();

  const [selectedOption, setSelectedOption] = useState('existing');
  const [newName, setNewName] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleNameChange = (event) => {
    setNewName(event.target.value);
  };

  const handleConfirm = () => {
    const result = {
      isNew: selectedOption === 'new',
      newName: selectedOption === 'new' ? newName : null,
    };
    onClose(result);
  };

  const handleCancel = () => {
    onClose(null);
  };

  const isConfirmDisabled = selectedOption === 'new' && !newName;

  const mainText = templateName ? translate`You made changes to the template '${templateName}', would you like to update the existing template or create a new one?` : translate`Do you want to save the changes as a new template or update the existing one?`

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth={false}>
      <DialogTitle className={classes.dialogTitle}>
        {translate`Choose an Option`}
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          className={classes.closeButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>{mainText}</Typography>
        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
          <FormControlLabel
            value="existing"
            control={<Radio />}
            label={translate`Save Existing`}
          />
          <FormControlLabel
            value="new"
            control={<Radio />}
            label={translate`Create New`}
          />
        </RadioGroup>
        {selectedOption === 'new' && (
          <TextField
            label={translate`New Name`}
            value={newName}
            onChange={handleNameChange}
            fullWidth
            margin="dense"
          />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ButtonV2 onClick={handleCancel} variant="outlined">
          {translate`Cancel`}
        </ButtonV2>
        <ButtonV2
          onClick={handleConfirm}
          variant="contained"
          disabled={isConfirmDisabled}
        >
          {translate`Confirm`}
        </ButtonV2>
      </DialogActions>
    </Dialog>
  );
};

const useConfirmationDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const resolveRef = useRef();

  const showDialog = useCallback(() => {
    return new Promise((resolve) => {
      resolveRef.current = resolve;
      setIsOpen(true);
    });
  }, []);

  const handleClose = useCallback((result) => {
    setIsOpen(false);
    resolveRef.current?.(result);
  }, []);

  const DialogComponent = useCallback(
    (props) => <ConfirmationDialog open={isOpen} onClose={handleClose} {...props} />,
    [isOpen, handleClose]
  );

  return [showDialog, DialogComponent];
};

export default useConfirmationDialog;
