export const bacnetTypeIdToShortName = {
  0: 'AI',
  1: 'AO',
  2: 'AV',
  3: 'BI',
  4: 'BO',
  5: 'BV',
  6: 'CAL',
  7: 'COM',
  8: 'D',
  9: 'EE',
  10: 'FILE',
  11: 'GR',
  12: 'LP',
  13: 'MI',
  14: 'MO',
  15: 'NC',
  16: 'PR',
  17: 'SC',
  18: 'AVG',
  19: 'MV',
  20: 'TL',
  21: 'LSP',
  22: 'LSZ',
  23: 'ACC',
  24: 'PC',
  25: 'EL',
  26: 'GG',
  27: 'TLM',
  28: 'LC',
  29: 'SV',
  30: 'AD',
  31: 'TMR',
  32: 'AC',
  33: 'AP',
  34: 'AR',
  35: 'AU',
  36: 'AZ',
  37: 'CDI',
  38: 'NS',
  39: 'BSV',
  40: 'CV',
  41: 'DPV',
  42: 'DV',
  43: 'DTPV',
  44: 'DTV',
  45: 'IV',
  46: 'LAV',
  47: 'OSV',
  48: 'PIV',
  49: 'TPV',
  50: 'TV',
  51: 'NF',
  52: 'AE',
  53: 'CH',
  54: 'LO',
  55: 'BLO',
  56: 'NP',
  57: 'EG',
  58: 'ES',
  59: 'LFT',
};

/**
 * Maps BACnet object type short names to their display colors.
 * Specific short names like 'D' map to 'purple', and unknown types default to 'magenta'.
 * @type {Object<string, string>}
 */
export const bacnetObjectTypeShortNameToColor = {
  D: 'purple',
  BI: '#557FAF',
  BO: '#9F98FD',
  BV: '#88C787', // Binary Object
  AI: '#D75C81',
  AO: '#A463DC',
  AV: '#EFD85C', // Analog Object
  MI: '#F26C5A',
  TL: '#ED9A5E', 
  CV: '#65A5E7',
  PR: '#B7BDC0',
  MO: 'blue',
  MV: '#A18149', // Multi State Object
};
