import React, { useMemo, useState, useRef, useEffect } from 'react';
import { MoonLoader } from 'react-spinners';
import { debounce, isEmpty } from 'lodash';

import { useStoreActions } from '@models/RootStore';
import { useGetBacnetUiTemplate } from '@hooks/useBacnetUiTemplatesApi';
import { useGetBacnetParamTemplate } from '@hooks/useBacnetParamTemplatesApi';
import { useSetBacnetObjectValue } from '@hooks/useBacnetDevicesApi';
import MappingArea from '@components/AddEditBacnetUiTemplateDialog/TemplateArea/MappingArea';
import { mergeUiTemplatePointsWithParamTemplate } from '@components/AddEditBacnetUiTemplateDialog/constants';

import useStyles from './styles';

const BacnetControlPanel = ({ entry, isCelsius, tempSymbol, siteTemp }) => {
  const { title, name, id } = entry;

  const classes = useStyles();

  const addWebSocketListener = useStoreActions(actions => actions.addWebSocketListener);
  const removeWebSocketListener = useStoreActions(actions => actions.removeWebSocketListener);

  const [dataPointsValues, setDataPointsValues] = useState(
    entry?.operationalData
  );
  const { data: uiTemplate, isLoading: isLoadingUiTemplate } =
    useGetBacnetUiTemplate(entry.uiTemplate);
  const { data: paramTemplate, isLoading: isLoadingParamTemplate } =
    useGetBacnetParamTemplate(uiTemplate?.paramTemplate);
  const { mutate: setObjectValue } = useSetBacnetObjectValue();

  // debounce setObjectValue so it doesn't fire on every change
  const debouncedSetObjectValue = useRef(
    debounce((objectId, value) => {
      setObjectValue({
        unitId: entry.id,
        payload: {
          object: objectId,
          value: value,
        },
      });
    }, 300) // 300ms debounce delay
  ).current;

  const onUpdateObjectValue = (objectId, value) => {
    setDataPointsValues((prev) => ({
      ...prev,
      [`${objectId}/85`]: value,
    }));

    // Call the debounced mutation
    debouncedSetObjectValue(objectId, value);
  };

  const temperatureDisplay = useMemo(() => {
    if (isCelsius && !siteTemp?.C) {
      return null;
    }

    if (!isCelsius && !siteTemp?.F) {
      return null;
    }

    // Determine the temperature and symbol to display
    const temperature = isCelsius
      ? Math.round(siteTemp.C)
      : Math.round(siteTemp.F);

    return (
      <>
        <span>{temperature}</span>
        <span className={classes.scaleStyle}>{tempSymbol}</span>
      </>
    );
  }, [isCelsius, siteTemp, tempSymbol]);

  // Merge with paramTemplate configuration
  const mergedDataPoints = useMemo(() => {
    return mergeUiTemplatePointsWithParamTemplate(
      paramTemplate?.data,
      uiTemplate?.data?.points
    );
  }, [paramTemplate, uiTemplate]);

  useEffect(() => {
    const handleWebSocketMessage = (message) => {
      if (message.name !== 'UPDATE_BACNET_UNIT_DATA' || message.data?.unitId != id) {
        return;
      }

      const { operationalData } = message.data;
      if(isEmpty(operationalData)) {
        return;
      }

      setDataPointsValues(operationalData);
    };

    // Subscribe to websocket messages
    addWebSocketListener(handleWebSocketMessage);

    // Cleanup subscription on unmount
    return () => {
      removeWebSocketListener(handleWebSocketMessage);
    };
  }, []);

  return (
    <div className={classes.bacnetControlPanel}>
      <div className={classes.bacnetControlPanel__header}>
        {temperatureDisplay && (
          <div className={classes.bacnetControlPanel__temperature}>
            {temperatureDisplay}
          </div>
        )}
        <div className={classes.bacnetControlPanel__unitName}>
          {title ?? name}
        </div>
      </div>
      <div className={classes.bacnetControlPanel__body}>
        {isLoadingUiTemplate || isLoadingParamTemplate ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '400px',
              width: '100%',
            }}
          >
            <MoonLoader size={50} color="#A1899F" />
          </div>
        ) : (
          <MappingArea
            dataPoints={mergedDataPoints}
            onChangeControlValue={onUpdateObjectValue}
            templateImg={uiTemplate?.fileURL}
            mode="control"
            dataPointsValues={dataPointsValues}
          />
        )}
      </div>
    </div>
  );
};

export default BacnetControlPanel;
