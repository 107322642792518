import React, { memo } from "react";
import { useStoreState } from "../../models/RootStore";
import UserMenu from "../UserMenu/UserMenu";
import useStyles from "./Header.style";
import SelectionsMenu, { ISelectionsMenuProps } from "./SelectionsMenu";

interface IHeaderProps extends ISelectionsMenuProps {
  path?: [string];
  // Accomodation for restProps
  hideAllOptionsFromDropdown?: string[];
  [key: string]: any;
  searchComponent?: any;
  pageCustomSelector?: any;
  textAllCustomers?: boolean;
  showBACnetDevices?: boolean;
  showBacnetDevicesMenu?: boolean;
  showBacnetLinesMenu?: boolean;
}

const Header = ({ path = [""], hideAllOptionsFromDropdown, textAllCustomers = false, searchComponent, customDateRangePickerComponent, ...restProps }: IHeaderProps) => {
  const c = useStyles();
  const userFullName = useStoreState((state) => state.users.myFullName);

  return (
    <div className={c.headerContainer}>
      <SelectionsMenu {...restProps} hideAllOptionsFromDropdown={hideAllOptionsFromDropdown} textAllCustomers={textAllCustomers} customDateRangePickerComponent={customDateRangePickerComponent} />
      <div className={c.rightSide}>
        {searchComponent}
        <UserMenu userName={userFullName} onSearch={!!searchComponent} />
      </div>
    </div>

  );
};

export default memo(Header);
