import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  Typography,
  TextField,
  IconButton,
} from '@material-ui/core';
import { Info as InfoIcon, Close } from '@material-ui/icons';
import { flatMap, orderBy } from 'lodash';
import { t } from 'ttag';
import useStyles from './styles';
import FilterRequire from '@components/FilterRequire/FilterRequire';
import Header from '@components/Header/Header';
import ServiceNavigationBar from '@components/Menu/ServiceNavigationBar';
import ButtonV2 from '@cool_widgets/ButtonV2';
import BacnetDeviceTableRow from './DevicesTableRow';
import useBacNet from '@hooks/useBacNet';
import { useStoreState } from '@models/RootStore';
import AddEditBacnetUiTemplateDialog from '@components/AddEditBacnetUiTemplateDialog';
import { useGetSiteBacnetUiTemplates } from '@hooks/useBacnetUiTemplatesApi';
import Search from './../../../svgComponents/MenuSearch';
import useConfirmationDialog from '@components/ConfirmationDialogV2';

/**
 * BacnetDeviceList component renders a list of BACnet devices for a selected site.
 *
 */
const BacnetDeviceList = (props) => {
  const classes = useStyles();
  const {
    siteId,
    bacnetDeviceId: selectedBacnetDeviceId,
    bacnetLineId: selectedBacnetLineId,
  } = useStoreState((state) => state.selections.selections);
  const allDevices = useStoreState((state) => state.devices.allDevices);
  const [ showConfirmationDialog, ConfirmationDialog ] = useConfirmationDialog();

  const [searchTerm, setSearchTerm] = useState("");

  const {
    isLoading,
    getSiteBacNetParameterTemplates,
    siteBacNetUnits,
    getSiteBacNetUnits,
    assignBacNetParameterTemplate,
    unassignBacNetParameterTemplate,
    updateBacNetUnit,
    assignBacNetUiTemplate,
    unassignBacNetUiTemplate,
    discoverBacnetUnitsByDevice,
    discoverBacnetUnitsBySite,
  } = useBacNet();

  const fetchData = useCallback(async () => {
    if (!siteId) {
      return;
    }
    await getSiteBacNetUnits(siteId);
    await getSiteBacNetParameterTemplates(siteId);
  }, [siteId]);

  // Handle discover devices
  const handDiscoverDevices = async () => {
    try {
      if(selectedBacnetDeviceId) {
        await discoverBacnetUnitsByDevice(selectedBacnetDeviceId, selectedBacnetLineId);
      } else {
        await discoverBacnetUnitsBySite(siteId);
      }

      showConfirmationDialog({
        title: t`Discover is requested`,
        bodyMessage: t`Discover is requested, please wait for a while and refresh the page to see the result. It may take a few minutes.`,
        confirmText: t`Ok`,
        cancelText: null,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleConnectionChange = async (bacnetUnitId, isEngaged) => {
    await updateBacNetUnit({
      id: bacnetUnitId,
      data: {
        isEngaged: isEngaged,
      },
    });
  };

  const handleParamTemplateAssign = async (tempId, bacnetUnitId) => {
    await assignBacNetParameterTemplate(tempId, bacnetUnitId);
  };

  const handleParamTemplateUnassign = async (tempId, bacnetUnitId) => {
    await unassignBacNetParameterTemplate(tempId, bacnetUnitId);
  };

  const handleUiTemplateAssign = async (tempId, bacnetUnitId) => {
    await assignBacNetUiTemplate(tempId, bacnetUnitId);
  };

  const handleUiTemplateUnassign = async (tempId, bacnetUnitId) => {
    await unassignBacNetUiTemplate(tempId, bacnetUnitId);
  };

  const goToUiTemplateMapping = (defaultParamTemplate) => {
    setDefaultParamTemplateForUiTemplate(defaultParamTemplate);
    setIsBacnetUiTemplateDialogShown(true);
  };

  // Initial fetch on mount or when siteId changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);
 
  // State to control the visibility of the AddEditBacnetUiTemplateDialog
  const [isBacnetUiTemplateDialogShown, setIsBacnetUiTemplateDialogShown] =
    useState(false);
  const [defaultParamTemplateForUiTemplate, setDefaultParamTemplateForUiTemplate] = useState(null);

  const { data: uiTemplates, refetch: refetchUiTemplates } =
    useGetSiteBacnetUiTemplates(siteId);

  // Sort devices by name asc
  const sortedBacnetDevices = useMemo(() => {
    return orderBy(
      Object.values(siteBacNetUnits).filter((entry) => {
        if (selectedBacnetDeviceId && entry.device !== selectedBacnetDeviceId) {
          return false;
        }
        if (selectedBacnetLineId && entry.line != selectedBacnetLineId) {
          return false;
        }

        if (searchTerm) {
          const searchTermLower = searchTerm.toLowerCase();
          const entryValues = flatMap(Object.values(entry).filter(entry => !!entry))
            .map(value => value.toString().toLowerCase());
          if (!entryValues.some(value => value.includes(searchTermLower))) {
            return false;
          }
        }
        return true;
      }),
      ['name'],
      ['asc']
    );
  }, [siteBacNetUnits, selectedBacnetDeviceId, selectedBacnetLineId, searchTerm]);

  const connectedBacnetDevicesCount = useMemo(() => {
    return sortedBacnetDevices.filter((device) => device.isConnected).length;
  }, [sortedBacnetDevices]);  
  
  const engagedBacnetDevicesCount = useMemo(() => {
    return sortedBacnetDevices.filter((device) => device.isEngaged).length;
  }, [sortedBacnetDevices]);

  const handleEditUiTemplateDialogClose = () => {
    setIsBacnetUiTemplateDialogShown(false);
    refetchUiTemplates();
  };

  const onDeleteBacknetDevice = () => {
    fetchData();
  };

  const searchComponent = (
    <TextField
      placeholder={t`Search...`}
      value={searchTerm}
      onChange={(event) => setSearchTerm(event.target.value)}
      InputProps={{
        disableUnderline: true, classes: { root: classes.inputRoot },
        endAdornment:
          !searchTerm ? (<Search />) : (
            <IconButton
              onClick={() => setSearchTerm("")}
              className={classes.closeIconStyle}
            >
              <Close />
            </IconButton>
          )
      }}
    />
  );

  const tableHeaders = [
    t`Device Name`,
    t`Device ID`,
    t`CA device`,
    t`Line`,
    t`Vendor ID`,
    t`Org Name`,
    t`MAC Addr`,
    t`Model`,
    t`Mapping`,
    t`Control UI`,
    t`Binding Status`,
    t` `,
  ];

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header
          customGeneralNames={{ site: t`Select Site` }}
          hideSystemSelection
          hideUnitSelection
          screenTitle="siteBacNetUnits"
          showBacnetDevicesMenu={true}
          showBacnetLinesMenu={true}
          searchComponent={searchComponent}
        />
        
        {!siteId ? (
          <FilterRequire type={t`site`} />
        ) : (
          <>
            <Paper elevation={0} className={classes.paperTableContainer}>
              {!isLoading.getSiteBacNetUnits &&
                sortedBacnetDevices?.length > 0 && (
                <div className={classes.pageHeader}>
                  <Typography>
                    {`${sortedBacnetDevices.length} ${t`discovered`} (${connectedBacnetDevicesCount} ${t`connected`}, ${engagedBacnetDevicesCount} ${t`bound to app`})`}
                  </Typography>
                  <ButtonV2 variant="contained" onClick={handDiscoverDevices}>
                    {t`Discover Devices`}
                  </ButtonV2>
                </div>
              )}
              <TableContainer className={classes.tableContainer}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map((entry, index) => {
                        return (
                          <TableCell
                            key={`tableHeaders-${index}`}
                            classes={{ root: classes.tableHeadCell }}
                            align="left"
                          >
                            {entry}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading.getSiteBacNetUnits ? (
                      <TableRow>
                        <TableCell colSpan={tableHeaders.length} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : sortedBacnetDevices?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={tableHeaders.length} align="center">
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <InfoIcon style={{ fontSize: 50 }} />
                            <Typography variant="h6">{t`No data available`}</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      sortedBacnetDevices.map((bacnetDevice) => {
                        const { canUpdate } = bacnetDevice?.permissions || {};
                        const rowProps = {
                          bacnetDevice: {
                            ...bacnetDevice,
                            serial: allDevices[bacnetDevice.device]?.serial,
                          },
                          canUpdate,
                          allDevices,
                          handleConnectionChange,
                          handleParamTemplateAssign,
                          handleParamTemplateUnassign,
                          handleUiTemplateAssign,
                          handleUiTemplateUnassign,
                          goToUiTemplateMapping,
                          onDeleteBacknetDevice
                        };
                        return (
                          <BacnetDeviceTableRow
                            siteId={siteId}
                            key={bacnetDevice.id}
                            uiTemplates={uiTemplates}
                            {...rowProps}
                          />
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <AddEditBacnetUiTemplateDialog
              open={isBacnetUiTemplateDialogShown}
              onClose={handleEditUiTemplateDialogClose}
              defaultParamTemplate={defaultParamTemplateForUiTemplate}
            />
            <ConfirmationDialog />
          </>
        )}
      </div>
    </div>
  );
};

export default BacnetDeviceList;
