import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { t } from 'ttag';
import clsx from 'clsx';
import FilterRequire from '@components/FilterRequire/FilterRequire';
import Header from '@components/Header/Header';
import ServiceNavigationBar from '@components/Menu/ServiceNavigationBar';
import { useStoreActions, useStoreState } from '@models/RootStore';
import useStyles from './styles';
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
  InputAdornment,
  Button as MuiButton,
} from '@material-ui/core';
import { orderBy, isEmpty, omit } from 'lodash';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { Delete } from 'src/svgComponents';
import { Refresh, SvgArrowDownO } from '@icons/index';
import NewTemplateDialog from './NewTemplateDialog';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ParamMappingDialog from './ParamMappingDialog';
import useBacNet from '@hooks/useBacNet';
import DynamicCircleIcon from '@components/DynamicCircleIcon';
import useTypes from '@hooks/useTypes';
import SvgSearch from '@icons/SearchIcon';
import { filterByTerm } from '@utils/ArrayUtils';
import { getUniqueId } from '@utils/StringUtils';
import useConfirmationDialog from '@components/AddEditBacnetUiTemplateDialog/SaveConfirmationModal';
import { ConfirmationDialog } from '@components/ConfirmationDialog';
import Button from '@cool_widgets/Button';
import EditInPlaceInput from '@components/EditInPlaceInput';
import useConfirmationDialogV2 from '@components/ConfirmationDialogV2';

/**
 * @typedef {Object} Template
 * @property {string} customer - The ID of the customer associated with the template.
 * @property {string} site - The ID of the site associated with the template.
 * @property {string} syncId - The synchronization ID of the template.
 * @property {string} name - The name of the template.
 * @property {string} model - The model name of the BACnet device.
 * @property {number} vendorId - The vendor ID of the BACnet device.
 * @property {string} createdAt - The ISO 8601 formatted creation timestamp.
 * @property {string} updatedAt - The ISO 8601 formatted last update timestamp.
 * @property {string} id - The unique identifier of the template.
 * @property {Object} data - Additional data associated with the template.
 * @property {Object} permissions - The permissions associated with the template.
 * @property {boolean} permissions.canRead - Indicates if the template can be read.
 * @property {boolean} permissions.canUpdate - Indicates if the template can be updated.
 * @property {boolean} permissions.canDelete - Indicates if the template can be deleted.
 */

const TemplateSelectionTypes = {
  new: {
    type: 'new',
    name: t`New`,
    saveText: t`Create`,
    title: t`Create New Mapping Template`,
    visible: false,
  },
  list: {
    type: 'list',
    name: t`From List`,
    saveText: t`Select`,
    title: t`Select Mapping Template`,
    visible: false,
  },
};

/**
 * BacNetMapping component handles the mapping of BacNet devices to parameter templates.
 * It allows for fetching BacNet device data, displaying a list of objects, and saving new or existing mapping templates.
 *
 * @component
 * @param {Object} props - Component properties.
 *
 * @returns {JSX.Element} The rendered BacNetMapping component.
 */
const BacNetMapping = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const { siteId = '', bacnetUnitId = '' } = match.params;

  const [showDialog, DialogComponent] = useConfirmationDialog();
  const [ showConfirmationDialogV2, ConfirmationDialogV2 ] = useConfirmationDialogV2();

  const {
    getSiteBacNetParameterTemplates,
    getBacNetUnitObjects,
    getBacNetUnitById,
    updateBacNetParameterTemplate,
    siteBacNetParameterTemplates,
    isLoading,
    updateBacNetUnit,
    getBacnetIconInformation,
    createMultipleBacNetParameterTemplates,
    updateMultipleBacNetParameterTemplates,
    discoverBacnetDeviceObjects
  } = useBacNet();

  const { bacnetMeasurementUnits, bacnetUnitTypes } = useTypes();

  const saveQueue = useRef({});

  const updateSelections = useStoreActions(
    (a) => a.selections.updateSelections
  );
  const { siteId: selectedSiteId } = useStoreState(
    (s) => s.selections.selections
  );

  const { bacnetMeasurementUnitEnums, bacnetCovOptionsTypes } = useStoreState(
    (state) => state.types
  );

  const [viewAllObject, setViewAllObject] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [templateSelectionType, setTemplateSelectionType] = useState(
    TemplateSelectionTypes['new']
  );
  const [bacNetDevice, setbacNetDevice] = useState({});
  const [bacnetUnitObjects, setBacnetUnitObjects] = useState({});
  const [selectedObjectId, setSelectedObjectId] = useState();
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [templateName, setTemplateName] = useState('');
  const [deletingObjectId, setDeletingObjectId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [allTemplates, setAllTemplates] = useState({});

  const [showBackConfirmation, setShowBackConfirmation] = useState(false);
  const [savedTemplateName, setSavedTemplateName] = useState('');
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);

  //This is needed because there is a component local state management for all templates before saving it into the store/BE.
  useEffect(() => {
    setAllTemplates(siteBacNetParameterTemplates);
  }, [siteBacNetParameterTemplates]);

  const templateSelectionOptions = useMemo(
    () => Object.values(TemplateSelectionTypes),
    [TemplateSelectionTypes]
  );

  const bacnetUnitTypesLabelsByValueMap = useMemo(() => {
    return Object.values(bacnetUnitTypes).reduce((map, { value, label }) => {
      map[value] = label;
      return map;
    });
  }, [bacnetUnitTypes]);

  const bacnetUnitObjectsOptions = useMemo(() => {
    const unitsArray = Object.values(bacnetUnitObjects);

    if (isEmpty(activeTemplate?.data)) {
      return unitsArray;
    }

    return unitsArray.map((unit) => ({
      ...unit,
      templateInfo: activeTemplate.data[unit.objectId] ?? null,
    }));
  }, [bacnetUnitObjects, viewAllObject, activeTemplate]);

  /** @type {Object<string,Object>} a map that holds the mapping objects on the current template by unit `objectId`.  */
  const activeTemplateDataMap = useMemo(() => {
    if (!activeTemplate?.data) {
      return {};
    }
    return activeTemplate.data;
  }, [activeTemplate]);

  /** An array of units that has a corresponding mapping */
  const bacnetUnitMappedObjectsOptions = useMemo(
    () => bacnetUnitObjectsOptions.filter(({ templateInfo }) => !!templateInfo),
    [bacnetUnitObjectsOptions]
  );

  useEffect(() => {
    if (!selectedSiteId && siteId) {
      updateSelections({ type: 'site', data: siteId });
    }
    if (selectedSiteId != siteId) {
      handleBack();
    }
  }, [siteId, selectedSiteId]);

  const fetchData = async () => {
    await getSiteBacNetParameterTemplates(siteId);
    const deviceData = await getBacNetUnitById(bacnetUnitId);
    setbacNetDevice(deviceData);
    const unitObjectsData = await getBacNetUnitObjects(bacnetUnitId);
    setBacnetUnitObjects(unitObjectsData);
  };

  useEffect(() => {
    if (!bacnetUnitId || !siteId) {
      return;
    }
    fetchData();
  }, [siteId, bacnetUnitId]);

  useEffect(() => {
    setTemplateName(activeTemplate?.name || '');
  }, [activeTemplate]);

  const generateNewTemplateItem = (name) => {
    const newTemplateDetails = {
      name,
      site: siteId,
      model: bacNetDevice.modelName,
      vendorId: bacNetDevice.vendorId,
    };

    return {
      id: getUniqueId(allTemplates),
      data: {},
      type: 'new',
      newTemplateDetails,
      ...newTemplateDetails,
    };
  };

  const { modifiedTemplates, newUnsavedTemplates, editedExistingTemplates } =
    useMemo(() => {
      const modifiedTemplates = Object.values(allTemplates).filter(
        ({ isModified }) => !!isModified
      );
      const newUnsavedTemplates = modifiedTemplates.filter(
        ({ type }) => type === 'new'
      );
      const editedExistingTemplates = modifiedTemplates.filter(
        ({ type }) => type !== 'new'
      );

      return {
        modifiedTemplates,
        newUnsavedTemplates,
        editedExistingTemplates,
      };
    }, [allTemplates]);

  const saveTemplatesToServer = async (
    newTemplatesToSave,
    existingTemplatesToSave = []
  ) => {
    const newFullTemplates = await createMultipleBacNetParameterTemplates(
      newTemplatesToSave
    );

    const newItemsToBatchEdit = newFullTemplates.map(
      ({ data, fullTemplate }) => {
        return { data: { data }, id: fullTemplate.id };
      }
    );

    const itemsToBatchEdit = [
      ...newItemsToBatchEdit,
      ...existingTemplatesToSave,
    ];

    if (itemsToBatchEdit.length) {
      setIsSavingTemplate(true);
      await updateMultipleBacNetParameterTemplates(itemsToBatchEdit);
    }

    setIsSavingTemplate(false);
  };

  const handleBack = () => {
    if (!modifiedTemplates.length) {
      history.push('/automation/bacnet-detection/');
    }
    setShowBackConfirmation(true);
  };

  const queueExistingSave = async (exitingTemplates) => {
    if (exitingTemplates) {
      saveQueue.current.items = exitingTemplates;
      saveQueue.current.index = 0;
      saveQueue.current.saveExistingParams = [];
      saveQueue.current.saveNewParams = [];
    }

    const { items, index, saveExistingParams, saveNewParams } =
      saveQueue.current;

    const template = items[index];

    if (!template) {
      return await saveTemplatesToServer(saveNewParams, saveExistingParams);
    }

    setSavedTemplateName(template.name);

    const userResponse = await showDialog();

    //User clicked cancel - skip the current template save.
    if (!userResponse) {
      saveQueue.current.index++;
      return queueExistingSave();
    }

    const { isNew, newName } = userResponse;

    if (isNew && newName) {
      const newItem = generateNewTemplateItem(newName);
      saveQueue.current.saveNewParams.push({
        ...newItem,
        data: template.data,
      });
    } else {
      const { data, id } = template;
      saveQueue.current.saveExistingParams.push({ data: { data }, id });
    }

    saveQueue.current.index++;
    queueExistingSave();
  };

  const saveTemplates = async () => {
    if (!modifiedTemplates.length) {
      return handleBack();
    }

    //save the current information for the function.
    const newTemplates = [...newUnsavedTemplates];
    const exitingTemplates = [...editedExistingTemplates];

    if (newTemplates.length) {
      saveTemplatesToServer(newTemplates);
    }

    if (exitingTemplates.length) {
      queueExistingSave(exitingTemplates);
    }
  };

  /**
   * Handles the toggle of the menu.
   * @param {Object} event - The event object.
   * @returns {void}
   */
  const handleToggle = (event) => {
    setMenuOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles the selection change from the dropdown menu.
   * @param {Object} event - The event object.
   * @returns {void}
   */
  const handleSelectChange = (event) => {
    setTemplateSelectionType({
      ...TemplateSelectionTypes[`${event.target.value}`],
      visible: true,
    });
    setMenuOpen(false);
  };

  /**
   * Saves a new or existing mapping template.
   * @param {string} selectedTemplateId - The ID of the selected template.
   */
  const handleSaveTemplate = async (selectedTemplateId) => {
    if (selectedTemplateId) {
      setActiveTemplate({
        ...allTemplates[selectedTemplateId],
        type: 'list',
      });
      setTemplateSelectionType(TemplateSelectionTypes['list']);
    } else {
      const newTemplateDetails = {
        name: templateName,
        site: siteId,
        model: bacNetDevice.modelName,
        vendorId: bacNetDevice.vendorId,
      };

      const newTemplate = {
        id: getUniqueId(allTemplates),
        data: {},
        type: 'new',
        newTemplateDetails,
        ...newTemplateDetails,
      };

      setAllTemplates((oldAllTemplates) => ({
        ...oldAllTemplates,
        [newTemplate.id]: newTemplate,
      }));
      setActiveTemplate(newTemplate);
      setTemplateSelectionType(TemplateSelectionTypes['new']);
    }
  };

  useEffect(() => {
    if (bacNetDevice.paramTemplate) {
      handleSaveTemplate(bacNetDevice.paramTemplate);
    }
  }, [bacNetDevice]);

  const handleDelete = async (event, objectId) => {
    event.stopPropagation();
    if (deletingObjectId || !activeTemplateDataMap[objectId]) {
      return;
    }
    setDeletingObjectId(objectId);
    try {
      const tempData = omit(activeTemplate?.data, objectId);
      const updatedTemplate = await updateBacNetParameterTemplate({
        id: activeTemplate.id,
        data: {
          data: tempData,
        },
      });
      setActiveTemplate(updatedTemplate);
    } finally {
      setDeletingObjectId(null);
    }
  };

  const handleSaveTemplateUpdates = (id, newData) => {
    const updatedTemplate = allTemplates[id];

    if (updatedTemplate) {
      updatedTemplate.data = newData;
      updatedTemplate.isModified = true;
      setActiveTemplate({ ...updatedTemplate });
      setAllTemplates((oldAllTemplates) => ({
        ...oldAllTemplates,
        [id]: { ...updatedTemplate },
      }));
      handleMappingDialogClose();
    } else {
      console.error(`Can't find template ${id}`);
    }
  };

  const closeTemplateSelectionDialog = () => {
    setTemplateSelectionType((prevParam) => ({
      ...prevParam,
      visible: false,
    }));
  };

  const handleRefresh = async () => {
    try {
      await discoverBacnetDeviceObjects(bacNetDevice?.bacnetDevice);
      showConfirmationDialogV2({
        title: t`Discover is requested`,
        bodyMessage: t`Discover is requested, please wait for a while and refresh the page to see the result. It may take a few minutes.`,
        confirmText: t`Ok`,
        cancelText: null,
      });
    } catch (e) {
      console.error('Failed to request discover:', e);
    }
  };

  const hideBackDialog = () => {
    setShowBackConfirmation(false);
  };

  const handleBackDialogYesClick = () => {
    hideBackDialog();
    saveTemplates();
  };

  const handleBackDialogNoClick = () => {
    setAllTemplates({});
    hideBackDialog();
    history.push('/automation/bacnet-detection/');
  };

  const handleTemplateNameChange = useCallback((e) => {
    setTemplateName(e.target.value);
  }, []);

  const handleRowClick = (object) => {
    if (isEmpty(activeTemplate)) {
      return; //todo: this should show an alert telling the user to set a template.
    }
    setSelectedObjectId(object);
  };

  const handleMappingDialogClose = () => {
    setSelectedObjectId();
  };

  const handleAppNameChange = async (newValue) => {
    const { id } = bacNetDevice;
    await updateBacNetUnit({
      id,
      data: {
        title: newValue,
      },
    });
    fetchData();
  };

  const handleSearchInputChange = ({ target }) => {
    const { value } = target;

    setSearchTerm(value);
  };

  const tableRows = useMemo(() => {
    const activeOptions = viewAllObject
      ? bacnetUnitObjectsOptions
      : bacnetUnitMappedObjectsOptions;
    return orderBy(activeOptions, ['name'], ['asc']);
  }, [viewAllObject, bacnetUnitObjectsOptions, bacnetUnitMappedObjectsOptions]);

  const filteredTableRows = useMemo(() => {
    if (!tableRows) {
      return [];
    }
    if (!searchTerm) {
      return tableRows;
    }
    return filterByTerm(tableRows, searchTerm, ['objectId', 'name']);
  }, [tableRows, searchTerm]);

  const getBackDialogText = () => {
    const hasEdited = !!editedExistingTemplates.length;
    const hasNew = !!newUnsavedTemplates.length;

    if (hasEdited && hasNew) {
      return t`You have created ${newUnsavedTemplates.length} new template and changed ${editedExistingTemplates.length} existing templates. would you like to save your changes?`;
    }

    if (hasEdited && !hasNew) {
      return t`You have changed ${editedExistingTemplates.length} existing templates. would you like to save your changes?`;
    }

    if (!hasEdited && hasNew) {
      return t`You have created ${newUnsavedTemplates.length} new template would you like to save your changes?`;
    }

    return '';
  };

  const getParsedRowData = (row) => {
    const dynamicWidth = 'calc((43% - 62px) / 3)';
    const { id, objectId, name, bacnetProps, bacnetType, templateInfo } = row;
    const {
      color,
      shortName,
      name: fullName,
    } = getBacnetIconInformation(bacnetType);
    const { UNITS } = bacnetProps;
    const units = bacnetMeasurementUnits[UNITS] ?? '-';
    const {
      name: mappedName = '-',
      type: mappedType,
      units: mappedUnits,
      cov: mappedCov = '-',
    } = templateInfo ?? {};

    const displayMappedType =
      bacnetUnitTypesLabelsByValueMap[mappedType] ?? '-';
    const displayMappedUnits = bacnetMeasurementUnitEnums?.[mappedUnits] ?? '-';
    const displayMappedCov =
      bacnetCovOptionsTypes?.[mappedCov]?.toUpperCase() ?? '-';

    return {
      dynamicWidth,
      id,
      objectId,
      name,
      color,
      shortName,
      fullName,
      units,
      mappedName,
      displayMappedType,
      displayMappedUnits,
      displayMappedCov,
      isMapped: !!templateInfo,
    };
  };

  const InformationTable = () => {
    return (
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.tableSmallHeadCell }} />
              <TableCell classes={{ root: classes.tableHeadCell }} align="left">
                {t`OBJECT ID`}
              </TableCell>
              <TableCell classes={{ root: classes.tableHeadCell }} align="left">
                {t`NAME`}
              </TableCell>
              <TableCell classes={{ root: classes.tableHeadCell }} align="left">
                {t`UNITS`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading.getBacNetUnitObjects &&
              filteredTableRows.map((row) => {
                const {
                  dynamicWidth,
                  id,
                  objectId,
                  name,
                  color,
                  shortName,
                  fullName,
                  units,
                } = getParsedRowData(row);

                return (
                  <TableRow
                    key={id}
                    hover
                    onDoubleClick={() => handleRowClick(id)}
                  >
                    <TableCell
                      align="center"
                      classes={{ root: classes.tableCell }}
                      style={{ width: 62 }}
                    >
                      <DynamicCircleIcon
                        color={color}
                        content={shortName}
                        title={fullName}
                        width={32}
                        height={32}
                      />
                    </TableCell>
                    <TableCell
                      classes={{
                        root: clsx(classes.tableCell, classes.tableCellBold, classes.nowrap),
                      }}
                      align="left"
                      style={{ width: dynamicWidth }}
                    >
                      {objectId}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCell }}
                      align="left"
                      style={{ width: dynamicWidth }}
                    >
                      {name}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCell }}
                      align="left"
                      style={{ width: dynamicWidth }}
                    >
                      {units}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const MappingTable = () => {
    return (
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.tableHeadCell }} align="left">
                {t`NAME`}
              </TableCell>
              <TableCell classes={{ root: classes.tableHeadCell }} align="left">
                {t`TYPE`}
              </TableCell>
              <TableCell classes={{ root: classes.tableHeadCell }} align="left">
                {t`UNITS`}
              </TableCell>
              <TableCell classes={{ root: classes.tableHeadCell }} align="left">
                {t`COV`}
              </TableCell>
              <TableCell
                classes={{ root: classes.tableSmallHeadCell }}
                align="center"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading.getBacNetUnitObjects &&
              filteredTableRows.map((row) => {
                const {
                  id,
                  objectId,
                  mappedName,
                  displayMappedType,
                  displayMappedUnits,
                  displayMappedCov,
                  isMapped,
                } = getParsedRowData(row);
                return (
                  <TableRow
                    key={id}
                    hover
                    onDoubleClick={() => handleRowClick(id)}
                  >
                    <TableCell
                      classes={{
                        root: clsx(classes.tableCell, classes.tableCellBold, classes.nowrap),
                      }}
                      align="left"
                    >
                      {mappedName}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCell }}
                      align="left"
                    >
                      {displayMappedType}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCell }}
                      align="left"
                    >
                      {displayMappedUnits}
                    </TableCell>
                    <TableCell
                      classes={{ root: classes.tableCell }}
                      align="left"
                    >
                      {displayMappedCov}
                    </TableCell>
                    <TableCell
                      align="center"
                      classes={{ root: classes.tableCell }}
                    >
                      {isMapped && <IconButton
                        onClick={(event) => handleDelete(event, objectId)}
                        disabled={deletingObjectId === objectId}
                        className={classes.mappbingTableDeleteBtn}
                      >
                        <Delete />
                      </IconButton>}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderContent = () => {
    if (!bacnetUnitId) {
      return <FilterRequire type={t`device`} />;
    }

    if (isLoading.getSiteBacNetParameterTemplates) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress style={{ alignSelf: 'center' }} />
        </div>
      );
    }

    return (
      <div className={classes.pageView}>
        <div className={classes.pageHeader}>
          <div className={classes.titleContainer}>
            <div className={classes.backBtnWrapper}>
              <button onClick={handleBack} className={classes.backBtn}>
                <KeyboardArrowLeft />
                <span>{t`Back`}</span>
              </button>
            </div>
            <div className={classes.pageTitle}>{t`Object Mapping`}</div>
            <div className={classes.ids}>
              <span>{bacNetDevice?.name}</span>
              <span>{bacNetDevice?.bacnetId}</span>
            </div>
          </div>
          <div className={classes.headerActionBlock}>
            <div className={classes.headerActionBlock__label}>
              {t`App Device Name`}
            </div>
            <div>
              <EditInPlaceInput
                value={bacNetDevice.title}
                onSave={handleAppNameChange}
              />
            </div>
          </div>
          <div className={classes.headerActionBlock}>
            <div className={classes.headerActionBlock__label}>
              {t`Mapping Template`}
            </div>
            <div>
              <MuiButton
                width={125}
                height={36}
                variant="outlined"
                onClick={handleToggle}
                className={classes.headerActionBlock__templateSrcMenu}
              >
                <span className="Name"> {activeTemplate ? activeTemplate.name : templateSelectionType.name}</span>
                <span className="VLine"></span>
                <SvgArrowDownO width={40} fill="#7F7692" />
              </MuiButton>
              <Select
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                onChange={handleSelectChange}
                value=""
                style={{ display: 'none' }}
                MenuProps={{
                  anchorEl: anchorEl,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {templateSelectionOptions.map(({ type, name }) => (
                  <MenuItem value={type} key={`key-${type}`}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className={classes.headerActionBlock}>
            <div>
              <TextField
                placeholder="Search"
                fullWidth
                value={searchTerm}
                onChange={handleSearchInputChange}
                variant="outlined"
                className={clsx(
                  classes.headerActionBlock__input,
                  classes.searchField
                )}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SvgSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className={classes.headerActionBlock}>
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={!modifiedTemplates.length || isSavingTemplate}
                onClick={saveTemplates}
              >
                {isSavingTemplate ? <CircularProgress size={20} /> : t`Save`}
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.mainContainer}>
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.bacnetInfoContainer}>
              <div className={clsx(classes.block)}>
                <div className={classes.block__header}>
                  <div
                    className={classes.block__title}
                  >{t`BACnet Information`}</div>
                  <div className={classes.block__actions}>
                    <div className={classes.allMappedFilter}>
                      <div
                        className={clsx(viewAllObject && 'IsSelected')}
                        onClick={() => setViewAllObject(true)}
                      >
                        {t`All Objects`} {`(${bacnetUnitObjectsOptions?.length})`}
                      </div>
                      <div
                        className={clsx(!viewAllObject && 'IsSelected')}
                        onClick={() => setViewAllObject(false)}
                      >
                        {t`Mapped`} {`(${bacnetUnitMappedObjectsOptions?.length})`}
                      </div>
                    </div>
                    <IconButton
                      onClick={handleRefresh}
                      className={classes.refreshBtn}
                    >
                      <Refresh width={20} height={20} />
                    </IconButton>
                  </div>
                </div>
                <div className={classes.block__body}>
                  <InformationTable />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={clsx(classes.block)}>
                <div className={classes.block__header}>
                  <div className={classes.block__title}>{t`Mapping Info`}</div>
                </div>
                <div className={classes.block__body}>
                  <MappingTable />
                </div>
              </div>
            </Grid>
          </Grid>
          {templateSelectionType.visible && (
            <NewTemplateDialog
              templateName={templateName}
              setTemplateName={setTemplateName}
              templateSelectionType={templateSelectionType}
              templatesList={allTemplates}
              handleClose={closeTemplateSelectionDialog}
              handleSave={handleSaveTemplate}
            />
          )}
          {selectedObjectId && !isEmpty(activeTemplate) && (
            <ParamMappingDialog
              bacNetObject={bacnetUnitObjects[selectedObjectId]}
              template={activeTemplate}
              handleSave={handleSaveTemplateUpdates}
              handleClose={handleMappingDialogClose}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.view}>
      <ServiceNavigationBar {...props} />
      <div className={classes.contentArea}>
        <Header hideUnitSelection hideSystemSelection />
        {renderContent()}
      </div>
      <DialogComponent templateName={savedTemplateName} />
      <ConfirmationDialog
        openDialog={showBackConfirmation}
        onConfirm={handleBackDialogYesClick}
        onCancel={handleBackDialogNoClick}
        onClose={hideBackDialog}
        title={t`Save changes?`}
        text={getBackDialogText()}
        confirmLabel={t`Yes`}
        cancelLabel={t`No`}
      />
      <ConfirmationDialogV2 />
    </div>
  );
};

export default BacNetMapping;
