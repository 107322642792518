import {
  Device as DeviceSdk,
  Site as SiteSdk,
  Unit as UnitSdk,
} from "coolremote-sdk";
import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  memo,
  thunk,
  Thunk,
} from "easy-peasy";
import _ from "lodash";
import { IAlert } from "./Alerts";
import { IRootStoreModel } from "./RootStore";

export interface IUnit {
  supportedcurveTempModes: any;
  heaterTempControl: number;
  id: string;
  type: number;
  name: string;
  isVisible: boolean;
  enableCoolMode: boolean;
  enableHeatMode: boolean;
  enableAutoMode: boolean;
  ambientTemperature?: number | 0;
  activeOperationMode: number | -1;
  activeOperationStatus: number | -1;
  readingValue?: string;
  readingValueTimestamp?: number;
  activeSetpoint?: number | 0;
  activeFanMode?: number | -1;
  activeLouverModes?: number | -1;
  device: string;
  zone: string | null;
  system: string | null;
  internalId: string;
  proId: string | null;
  line: number /* from the first 2 chars of unit name: L1, L2, etc */;
  privateId: string /* second part of unit name, after '.': '000', '003' etc */;
  role: any;
  isConnected?: boolean;
  enableSetpoint: boolean;
  enableMode: boolean;
  enableOnState: boolean;
  enableOnoff: boolean;
  supportedFanModes: number[];
  supportedSwingModes: number[];
  supportedOperationModes: number[];
  supportedOperationStatuses: number[];
  temperatureLimits: any;
  compressors?: string[];
  task: number;
  capacity?: number;
  capacityMeasurementUnits?: number;
  airnet?: number;
  model?: string;
  serialNumber?: string;
  site?: string;
  controlUnit?: string;
  serviceUnits?: string[];
  address?: string;
  schedules?: any;
  activeSwingMode?: any;
  branchBoxUnit?: any;
  bsBox?: any;
  otherUnits?: string[];
  zones: any[];
  groups?: any[];
  eWrcTemperatureLimits?: any;
  eWrcEnableCoolMode?: any;
  eWrcEnableHeatMode?: any;
  eWrcEnableAutoMode?: any;
  eWrcEnableOnoff?: any;
  eWrcEnableMode?: any;
  eWrcEnableSetpoint?: any;
  eWrcDisable?: any;
  eWrcEnableOnState?: any;
  brand?: any;
  heaterTankONOFF?: number;
  subType?: any;
  customer?: any;
  bsBoxUnit?: any;
  maxPowerConsumption: number;
  lastPowerConsumption: number;
  isHalfCDegreeEnabled?: boolean;
  capabilityFlags?: any;
  message?: any;
  filter?: any;
  canAddNote: boolean;
  canDeleteNote: boolean;
  showControlAppAutoOffTimer?: boolean;
  permissions?: any;
}

interface IUpdatePayload {
  unitId: string;
  data: any;
}

interface IMassUpdatePayload {
  unitsArr: {
    unitId: string;
    serialNumber?: string;
    name?: string;
    model?: string;
    task?: number;
    capacity?: number;
    capacityMeasurementUnits?: number;
    isVisible?: boolean;
  }[];
}

interface IPowerPayload {
  unitId: string;
  state: number;
}

export interface IUnitMap {
  [key: string]: IUnit;
}

export interface IUnitAffiliation {
  customerId?: string;
  siteId?: string;
  systemId?: string;
  deviceId?: string;
}

export interface IGetUnitStatsParams {
  unitIds?: any[];
  unitId?: string;
  startTime: number;
  endTime: number;
  withQuality?: boolean;
  params?: string[];
}

export interface IGetUnitStatsResult {
  unitSupportedParams: any[];
  unitProStats: { ranges: any; results: any[] };
  unitBasicStats: any[];
  updateTime: any;
}

interface IStatsParams {
  unitId: string;
  startTime: number;
  endTime: number;
  size: number;
}

interface IChangeSystem {
  unitId: string;
  oldSystem: string;
  newSystem: string;
}

export type IEntityType = "customer" | "site" | "system";
export type IUnitType =
  | "all"
  | "control"
  | "indoor"
  | "outdoor"
  | "service"
  | "bsBox"
  | "other";

export interface IUnitsModel {
  allUnits: IUnitMap;
  getUnit: Computed<
    IUnitsModel,
    (unitId: string | null | undefined) => IUnit | undefined,
    IRootStoreModel
  >;
  getUnitName: Computed<
    IUnitsModel,
    (unitId: string | null | undefined, renameServiceUnits?: boolean) => string,
    IRootStoreModel
  >;
  getUnitType: Computed<
    IUnitsModel,
    (
      unitId: string | null | undefined
    ) => "Indoor" | "Outdoor" | "Control" | "-" | "Other",
    IRootStoreModel
  >;
  getUnitTypeByTypes: Computed<
    any,
    (unitId: string | null | undefined) => string,
    IRootStoreModel
  >;
  getUnitAffiliation: Computed<
    IUnitsModel,
    (unitId: string) => IUnitAffiliation,
    IRootStoreModel
  >;
  getUnitsBy: Computed<
    IUnitsModel,
    (
      entityType: IEntityType,
      entityId: string | null,
      options?: { type?: IUnitType }
    ) => IUnit[],
    IRootStoreModel
  >;
  getUnitByPrivateId: Computed<
    IUnitsModel,
    (
      privateId: string,
      deviceSerial: string,
      line: number,
      siteId: string
    ) => IUnit,
    IRootStoreModel
  >;
  countUnitsFor: Computed<
    IUnitsModel,
    (
      entityType: IEntityType,
      entityId: string,
      controlUnits?: boolean
    ) => number,
    IRootStoreModel
  >;
  getUnitAlerts: Computed<
    IUnitsModel,
    (unitId: string) => IAlert[],
    IRootStoreModel
  >;
  getUnitSystemBrandName: Computed<
    IUnitsModel,
    (unitId: string) => string | null,
    IRootStoreModel
  >;
  getUnitSystemBrand: Computed<
    IUnitsModel,
    (unitId: string) => number | null,
    IRootStoreModel
  >;
  getUnitByInternalOrProId: Computed<
    IUnitsModel,
    (internalOrProId: string) => IUnit | null
  >;
  getUnitById: Computed<IUnitsModel, (unitDbId: string) => IUnit | null>;
  getUnitByIdSDK: Thunk<IUnitsModel, string>;
  isItMe: Computed<
    IUnitsModel,
    (unit: IUnit, internalOrProId: string) => boolean
  >;
  getTableData: Thunk<
    IUnitsModel,
    IGetUnitStatsParams /* payload : unitId */,
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any /* result */
  >;
  getUnitStats: Thunk<
    IUnitsModel,
    IGetUnitStatsParams /* payload : unitId */,
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any /* result */
  >;

  getUnitSupportedParams: Thunk<
    IUnitsModel,
    { unitId: string } /* payload : unitId */,
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any /* result */
  >;

  initialize: Action<IUnitsModel, any>;
  updateUnitLocally: Action<IUnitsModel, { id: string; unit: any }>;
  updateMultiUnitsLocally: Action<IUnitsModel, { [key: string]: any }>;
  onInitialized: ActionOn<IUnitsModel, IRootStoreModel>;

  updateUnit: Thunk<
    IUnitsModel,
    { id: string; updatedData: any; updateAssociated?: boolean }
  >;
  updateUnits: Thunk<IUnitsModel, IMassUpdatePayload, any>;
  getUnits: Thunk<IUnitsModel>;
  fetchSiteUnits: Thunk<IUnitsModel, string>;
  updateMultiUnit: Thunk<IUnitsModel, any>;
  getAllUnits: Thunk<IUnitsModel>;
  setParamValue: Thunk<
    IUnitsModel,
    { id: string; serviceParamCode: any; value: any }
  >;
  createMultiDualSetpiontRules: Thunk<
    IUnitsModel,
    {
      heatSP: number;
      coolSP: number;
      units: string[];
      site: string;
    }
  >;
  createDualSetpiontRule: Thunk<
    IUnitsModel,
    {
      heatSP: number;
      coolSP: number;
      unit: string[];
    }
  >;

  updateDualSetpiontRule: Thunk<
    IUnitsModel,
    {
      ruleId: string;
      heatSP: number;
      coolSP: number;
    }
  >;
  getUnitDualSetpiontRule: Thunk<IUnitsModel, string>;

  cleanFilterUnit: Thunk<IUnitsModel, { id: string }>;
  deleteUnit: Thunk<IUnitsModel, string>;
  deleteUnits: Thunk<IUnitsModel, string[]>;
  _storeCreateUnit: Action<IUnitsModel, { id: string; data: IUnit }>;
  _storeUpdateUnit: Action<IUnitsModel, { id: string; data: Partial<IUnit> }>;
  updateUnitsState: Action<IUnitsModel, any>;
  _storeDeleteUnit: Action<IUnitsModel, { id: string }>;
  _storeDeleteUnits: Action<IUnitsModel, { ids: string[] }>;
  _storeALlUnits: Action<IUnitsModel, any>;

  setUnitSystem: Action<IUnitsModel, { id: string; systemId: any }>;
  setUnitName: Action<IUnitsModel, { id: string; unitName: any }>;

  setActiveSetpoint: Thunk<IUnitsModel, { id: string; setpoint: any }>;
  updateLocalSetpoint: Action<IUnitsModel, { id: string; setpoint: any }>;
  setActiveOperationMode: Thunk<IUnitsModel, { id: string; mode: any }>;
  togglePower: Thunk<IUnitsModel, { id: string; activeOperationStatus: any }>;
  setUnitActiveSetpoint: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitActiveOperationMode: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitActiveFanMode: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitActiveSwingMode: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitActiveOperationStatus: Thunk<IUnitsModel, IUpdatePayload>;
  setUnitsActiveOperationStatus: Thunk<IUnitsModel, any>;
  changePowerState: Thunk<IUnitsModel, IPowerPayload>;
  getAndReplaceUnits: Thunk<IUnitsModel, void>;
  createOtherUnit: Thunk<
    IUnitsModel,
    { deviceId: string; line: number; name: string }
  >;
  getUnitParamsAndStats: Thunk<
    IUnitsModel,
    {
      unitId: string;
      startTime: number;
      endTime: number;
      params?: any[];
      isReduced?: boolean;
    }
  >;
  getUnitCategories: Thunk<IUnitsModel, string>;
  getUnitCategoryParams: Thunk<
    IUnitsModel,
    { unitId: string; category: string }
  >;
  getUnitCategoryParamsByParams: Thunk<
    IUnitsModel,
    { unitId: string; params: any }
  >;
  updateUnitParamsValues: Thunk<IUnitsModel, { id: string; data: any }>;
  getSensorsAndPowerMetersStats: Thunk<
    IUnitsModel,
    { startTimeUTC: number; endTimeUTC: number; siteId: string; params: any }
  >;
  storeUpdateUnitsZones: Action<
    IUnitsModel,
    { unitId: string; zone: any; add: boolean }
  >;
  assoControlAndService: Thunk<
    IUnitsModel,
    { controlId: string; srvId: string }
  >;
  getUnitsByWithHiddenUnits: Computed<
    IUnitsModel,
    (
      entityType: IEntityType,
      entityId: string | null,
      options?: { type: IUnitType; subType: any }
    ) => IUnit[],
    IRootStoreModel
  >;
  getFilteredUnits: Computed<
    IUnitsModel,
    (
      entityType: IEntityType,
      entityId: string | null,
      options?: { type: IUnitType },
      renameServiceUnits?: boolean
    ) => any[],
    IRootStoreModel
  >;
  getUnitInfo: Computed<IUnitsModel, (id: string) => any, IRootStoreModel>;
  updateUnitsAndSchedules: Thunk<IUnitsModel, { updatedData: any }>;
  getEWrcLock: Thunk<
    IUnitsModel,
    { id: string },
    any /* injections */,
    IRootStoreModel /* types for getStoreState/getStoreActions */,
    any /* result */
  >;
  setEWrcLock: Thunk<IUnitsModel, { id: string; data: any }>;
  setManyEWrcLocks: Thunk<IUnitsModel, { data: any }>;
  toggleEWrcLock: Thunk<IUnitsModel, { units: any; isEWrcDisable: boolean }>;
  updateCustomParam: Thunk<IUnitsModel, { id: string; data: any }>;
  createCustomParam: Thunk<IUnitsModel, { data: any }>;
  deleteCustomParam: Thunk<IUnitsModel, string>;
  getUnitStatistics: Thunk<IUnitsModel, IStatsParams, IRootStoreModel>;
  fetchUnitDiagByParams: Thunk<
    IUnitsModel,
    { unitId: string; startTime: number; endTime: number; params: string[] }
  >;
  updateWaterHeaterDHWTankSwitch: Thunk<IUnitsModel, { id: string; data: any }>;
  getUnitsFromStore: Thunk<IUnitsModel>;
  updateWaterHeaterDHWTankSetpoint: Thunk<
    IUnitsModel,
    { id: string; data: any }
  >;
  updateWaterHeaterMode: Thunk<IUnitsModel, { id: string; data: any }>;
  updateWaterHeaterBooster: Thunk<IUnitsModel, { id: string; data: any }>;
  updateWaterHeaterCoolingSetpoint: Thunk<
    IUnitsModel,
    { id: string; data: any }
  >;
  updateWaterHeaterHeatingSetpoint: Thunk<
    IUnitsModel,
    { id: string; data: any }
  >;
  updateServiceUnitsForMultiUnits: Action<IUnitsModel, []>;
  updateMultipleUnitsLocally: Thunk<
    IUnitsModel,
    { filter: string; filterVal: any; key: any; value: any }
  >;
  storeUpdateUnitsArrayZones: Action<
    IUnitsModel,
    { units: any; zone: any; add: boolean }
  >;
  updateUnitSystem: Thunk<IUnitsModel, IChangeSystem, any>;
  getOnUnitsByMode: Thunk<
    IUnitsModel,
    { customerId: string; siteId: string; mode: number }
  >;
  getAllOnUnits: Thunk<IUnitsModel, { customerId: string; siteId: string }>;
  setUnitLocally: Action<IUnitsModel, any>;
}

export const unitsModel: IUnitsModel = {
  allUnits: {},
  initialize: action((state, payload) => {
    state.allUnits = payload;
  }),
  updateUnitLocally: action((state, payload) => {
    const { id, unit } = payload;
    state.allUnits = {
      ...state.allUnits,
      [id]: { ...state.allUnits[id], ...unit },
    };
  }),
  updateMultiUnitsLocally: action((state, payload) => {
    const updatedUnits = {} as any;
    Object.entries(payload).forEach(([id, unit]) => {
      updatedUnits[id] = { ...state.allUnits[id], ...unit };
    });
    state.allUnits = { ...state.allUnits, ...updatedUnits };
  }),

  getUnits: thunk(async actions => {
    const allUnits = await UnitSdk.getUnitsBasic();
    actions._storeALlUnits(allUnits);
    return allUnits;
  }),
  fetchSiteUnits: thunk(async (actions, payload) => {
    const siteUnits = await SiteSdk.getUnits(payload);
    actions.updateUnitsState(siteUnits);
    return siteUnits;
  }),
  updateMultiUnit: thunk(async (actions, payload) => {
    return UnitSdk.updateUnitMulti(payload);
  }),
  getAllUnits: thunk((actions, state) => {
    return UnitSdk.getUnits();
  }),

  onInitialized: actionOn(
    (actions, storeActions) => [actions.initialize],
    (state, target) => {}
  ),

  getUnit: computed([state => state.allUnits], allUnits =>
    memo(unitId => {
      if (_.isNil(unitId)) return undefined;
      return allUnits[unitId];
    }, 100)
  ),

  getUnitName: computed([state => state.allUnits], allUnits =>
    memo((unitId, renameServiceUnits = false) => {
      if (_.isNil(unitId)) return "-";
      if (renameServiceUnits) {
        const unit = allUnits[unitId];
        if (!unit) {
          return "";
        }
        let unitName: string = unit.name;

        if (+unit?.type === 3) {
          // is service unit
          const controlUnitId: any = unit.controlUnit;
          const controlName = allUnits[controlUnitId]?.name || `Unassigned`;
          unitName = `${controlName} (${unit.address})`;
        }
        return unitName;
      }
      return allUnits[unitId]?.name;
    }, 100)
  ),

  getUnitType: computed([state => state.allUnits], allUnits =>
    memo(unitId => {
      if (_.isNil(unitId)) return "-";
      return allUnits[unitId]?.type === 2
        ? "Outdoor"
        : allUnits[unitId]?.type === 3
        ? "Indoor"
        : allUnits[unitId]?.type === 5
        ? "Other"
        : "Control";
    }, 100)
  ),
  getUnitTypeByTypes: computed(
    [
      state => state.allUnits,
      (state, storeState: any) => storeState.unitTypesMirrror,
    ],
    (allUnits, unitTypesMirrror) =>
      memo(unitId => {
        if (_.isNil(unitId)) return "-";
        return unitTypesMirrror[allUnits[unitId]?.type || ""] || "-";
      }, 100)
  ),
  getUnitAffiliation: computed(
    [
      state => state.allUnits,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.devices.allDevices,
    ],
    (allUnits, allSites, allDevices) =>
      memo(unitId => {
        const aff = {} as IUnitAffiliation;
        if (unitId && allUnits[unitId]) {
          const unit = allUnits[unitId];
          aff.systemId = unit.system || unit.line + "_" + unit.device;

          const unitDevice = allDevices[unit.device];
          if (_.isUndefined(unitDevice)) return aff;
          aff.deviceId = unitDevice.id || undefined;

          const unitSite = allSites[unitDevice.site];
          if (!_.isUndefined(unitSite)) {
            aff.siteId = unitSite.id || undefined;
            aff.customerId = unitSite.customer || undefined;
          }
        }
        return aff;
      }, 100)
  ),
  getUnitByPrivateId: computed(
    [
      state => state.allUnits,
      (state, storeState) => storeState.devices.getDeviceBySerial,
    ],
    (allUnits, getDeviceBySerial) =>
      memo((privateId, deviceSerial, line, siteId) => {
        const device = getDeviceBySerial(deviceSerial);
        return Object.values(allUnits).filter(unit => {
          if (
            privateId === unit.privateId &&
            unit.device === device?.id &&
            line === unit.line &&
            unit.site === siteId
          ) {
            return true;
          }
        })[0];
      }, 100)
  ),
  getUnitsBy: computed(
    [state => state.allUnits, state => state.getUnitAffiliation],

    (allUnits, getUnitAffiliation) =>
      memo((entityType, entityId, options = { type: "all" }) => {
        // entityId of undefined, null or '' gets all units
        if (_.isNil(entityId) || entityId.length === 0) {
          return Object.values(allUnits);
        } else {
          return Object.values(allUnits).filter(unit => {
            // Apply filter from options
            // Unit types: 1 - indoor, 2 - outdoor
            if (
              (options?.type === "control" || options?.type === "indoor") &&
              !unit.isVisible
            ) {
              return false;
            }

            if (
              options?.type &&
              ((options.type === "control" && unit.type !== 1) ||
                (options.type === "indoor" && unit.type !== 3) ||
                (options.type === "outdoor" && unit.type !== 2) ||
                (options.type === "bsBox" && unit.type !== 4) ||
                (options.type === "other" && unit.type !== 5))
            ) {
              return false;
            }

            // Apply filter by entity type
            const unitAff = getUnitAffiliation(unit.id);
            switch (entityType) {
              case "customer":
                return entityId === unitAff.customerId;
              case "site":
                return entityId === unitAff.siteId;
              case "system":
                return entityId === unitAff.systemId;
            }
          });
        }
      }, 100)
  ),

  countUnitsFor: computed(
    [
      state => state.allUnits,
      state => state.getUnitAffiliation,
      (state, storeState) => storeState.systems.allSystems,
      (state, storeState) => storeState.sites.sitesFlags,
    ],
    (allUnits, getUnitAffiliation, allSystems, sitesFlags) =>
      memo((entityType, entityId, controlUnits = false) => {
        if (entityType === "system" && !allSystems[entityId]) {
          const unassignedUnits: any = Object.values(allUnits).filter(
            (unit: any) => _.isNil(unit.system)
          );
          let systems: any = {};
          for (let unit of unassignedUnits) {
            if (!systems[unit.line + "_" + unit.device]) {
              systems[unit.line + "_" + unit.device] = [unit];
            } else {
              systems[unit.line + "_" + unit.device].push(unit);
            }
          }
          return systems[entityId].length;
        }
        return Object.values(allUnits).filter((unit: any) => {
          const { type, isVisible, controlUnit, site } = unit;
          const { isServiceSite = false } = sitesFlags[site] || {};

          if (!isVisible) {
            return false;
          }
          const isControlUnit = type === 1;
          const isIndoorUnit = isServiceSite ? type === 3 : type === 1;

          switch (entityType) {
            case "customer":
              return (
                (controlUnits ? isControlUnit : isIndoorUnit) &&
                entityId === unit.customer
              );
            case "site":
              return (
                (controlUnits ? isControlUnit : isIndoorUnit) &&
                entityId === unit.site
              );
            case "system":
              return (
                (controlUnits ? isControlUnit : isIndoorUnit) &&
                entityId === unit.system
              );
          }
        }).length;
      }, 100)
  ),
  getUnitAlerts: computed(
    [
      state => state.allUnits,
      state => state.isItMe,
      (state, storeState) => storeState.alerts.allOpenAlerts,
    ],
    (allUnits, isItMe, allOpenAlerts) =>
      memo(unitId => {
        const unit = allUnits[unitId];
        return Object.values(allOpenAlerts).filter(
          alert =>
            _.has(alert, "data.eventData.unitId") &&
            alert.data.eventData.unitId &&
            isItMe(unit, alert.data.eventData.unitId)
        );
      }, 100)
  ),

  getUnitSystemBrandName: computed(
    [
      state => state.allUnits,
      (state, storeState) => storeState.systems.allSystems,
    ],
    (allUnits, allSystems) =>
      memo(unitId => {
        const unit = allUnits[unitId];

        if (_.isUndefined(unit)) {
          return null;
        }
        if (_.isNil(unit.system)) {
          return null;
        }

        const system = allSystems[unit.system];

        if (_.isNil(system)) {
          return null;
        }
        if (_.isNil(system.name)) {
          return null;
        }

        return system.name;
      }, 100)
  ),
  getUnitSystemBrand: computed(
    [
      state => state.allUnits,
      (state, storeState) => storeState.systems.allSystems,
    ],
    (allUnits, allSystems) =>
      memo(unitId => {
        const unit = allUnits[unitId];
        if (_.isUndefined(unit)) {
          return null;
        }
        if (_.isNil(unit.system)) {
          return null;
        }

        const system = allSystems[unit.system];

        if (_.isNil(system)) {
          return null;
        }
        if (_.isNil(system.brandNum)) {
          return null;
        }
        return system.brandNum;
      }, 100)
  ),

  getUnitByInternalOrProId: computed(
    [state => state.allUnits],
    allUnits =>
      memo(internalOrProId => {
        let foundUnit: IUnit | null = null;

        // Internal ID first!
        for (let unitId in allUnits) {
          const unit: IUnit = allUnits[unitId];

          if (unit.internalId === internalOrProId) {
            foundUnit = unit;
            break;
          }
        }

        // If not found by internal ID, search by pro ID.
        if (_.isNull(foundUnit)) {
          for (let unitId in allUnits) {
            const unit: IUnit = allUnits[unitId];

            if (
              !_.isNil(unit.proId) &&
              !_.isEmpty(unit.proId) &&
              internalOrProId &&
              internalOrProId.indexOf(":") !== -1 &&
              internalOrProId.split(":")[1].substr(0, 6) === unit.proId
            ) {
              foundUnit = unit;
              break;
            }
          }
        }

        return foundUnit;
      }, 100) // 1000
  ),

  getUnitByIdSDK: thunk(async (actions, payload) => {
    const unit = await UnitSdk.getUnitById(payload);
    return unit;
  }),

  getUnitById: computed(
    [state => state.allUnits],
    allUnits =>
      memo(unitDbId => {
        const unit: IUnit = allUnits[unitDbId];
        if (unit) {
          return unit;
        }
        return null;
      }, 100) // 1000
  ),

  isItMe: computed([state => state.allUnits], allUnits =>
    memo((unit, internalOrProId) => {
      if (unit.internalId === internalOrProId) {
        return true;
      }

      if (
        !_.isNil(unit.proId) &&
        !_.isEmpty(unit.proId) &&
        internalOrProId.indexOf(":") !== -1 &&
        internalOrProId.split(":")[1].substr(0, 6) === unit.proId
      ) {
        return true;
      }

      return false;
    }, 100)
  ),

  getUnitStats: thunk(
    async (
      actions,
      { unitId, startTime, endTime, withQuality = false },
      state
    ) => {
      let unitSupportedParams: any = [];
      let unitProStats = { ranges: {}, results: [] };
      let updateTime: any;
      const serviceParams = state.getStoreState().serviceParams;

      let promises: any = [];

      // unitSupportedParams = res.supportedColumns;
      // updateTime = res.updateTimestamp;
      return UnitSdk.getProSupportedServiceParams(unitId)
        .then((res: any) => {
          unitSupportedParams = res.supportedColumns;
          updateTime = res.updateTimestamp;

          if (unitSupportedParams && unitSupportedParams.length) {
            // const unitSupportedParamsCodes = _.map(unitSupportedParams, (param) => param.code);

            const unitSupportedParamsCodes = unitSupportedParams.reduce(
              (filtered: any, param: any) => {
                if (param?.code && serviceParams[param.code]?.plotable) {
                  filtered.push(param.code);
                }
                //plotable
                return filtered;
              },
              []
            );

            // Period to get stats for
            let granularity = "minute";
            if (unitSupportedParamsCodes.length) {
              promises.push(
                UnitSdk.getProStats(
                  unitId,
                  startTime,
                  endTime,
                  granularity,
                  withQuality,
                  unitSupportedParamsCodes
                )
              );
            }
          }

          return Promise.all(promises).then((resp: any) => {
            if (resp[0]) {
              unitProStats = resp[0];
            }
          });
        })
        .then(() => {
          return {
            unitSupportedParams,
            unitProStats,
            updateTime,
          };
        });
    }
  ),
  getUnitSupportedParams: thunk(async (actions, { unitId }, state) => {
    let unitSupportedParams: any = [];
    let updateTime: any;
    return UnitSdk.getProSupportedServiceParams(unitId)
      .then((res: any) => {
        unitSupportedParams = res.supportedColumns;
        updateTime = res.updateTimestamp;
      })
      .then(() => {
        return {
          unitSupportedParams,
          updateTime,
        };
      });
  }),
  getTableData: thunk(
    async (actions, { unitIds, startTime, endTime, params }, state) => {
      return UnitSdk.getProExtendedServiceParams({
        units: unitIds,
        startTime,
        endTime,
        params,
        noreduce: true,
      });
    }
  ),

  setUnitName: action((state, payload) => {
    state.allUnits[payload.id].name = payload.unitName;
  }),

  setUnitSystem: action((state, payload) => {
    state.allUnits[payload.id].system = payload.systemId;
  }),

  updateLocalSetpoint: action((state, payload) => {
    state.allUnits = {
      ...state.allUnits,
      [payload.id]: {
        ...state.allUnits[payload.id],
        activeSetpoint: payload.setpoint,
      },
    };
  }),

  setActiveSetpoint: thunk(async (actions, payload) => {
    UnitSdk.setActiveSetpoint(payload.id, payload.setpoint).then(() => {
      actions.updateLocalSetpoint(payload);
    });
  }),

  setActiveOperationMode: thunk(async (actions, payload) => {
    await UnitSdk.setActiveOperationMode(payload.id, payload.mode);
    actions._storeUpdateUnit({
      id: payload.id,
      data: { activeOperationMode: payload.mode },
    });
  }),

  togglePower: thunk(async (actions, payload) => {
    const toggleStates = [1, 2, 1];
    const status = toggleStates[payload.activeOperationStatus];
    await UnitSdk.setActiveOperationStatus(payload.id, status);
    actions._storeUpdateUnit({
      id: payload.id,
      data: { activeOperationStatus: status },
    });
  }),

  updateUnit: thunk(async (actions, payload) => {
    const data = await UnitSdk.update(
      payload.id,
      payload.updatedData,
      payload?.updateAssociated
    );

    // Why call _storeCreateUnit()? Because it assigns the answer as-is.
    actions._storeCreateUnit({ id: data.id, data });
    return data;
  }),

  updateUnits: thunk(async (actions, payload, { getState }) => {
    const state = getState();
    try {
      await UnitSdk.updateUnits(payload);
      for (const updatedUnit of payload.unitsArr) {
        actions._storeCreateUnit({
          id: updatedUnit.unitId,
          data: { ...state.allUnits[updatedUnit.unitId], ...updatedUnit },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }),

  createMultiDualSetpiontRules: thunk(async (actions, payload) => {
    return UnitSdk.createMultiDualSetpiontRules(payload);
  }),

  createDualSetpiontRule: thunk(async (actions, payload) => {
    return UnitSdk.createDualSetpiontRule(payload);
  }),

  updateDualSetpiontRule: thunk(async (actions, payload) => {
    const { ruleId, ...data } = payload;
    return UnitSdk.updateDualSetpiontRule(ruleId, data);
  }),

  getUnitDualSetpiontRule: thunk(async (actions, payload) => {
    return UnitSdk.getUnitDualSetpiontRule(payload);
  }),

  setParamValue: thunk(async (actions, payload) => {
    const data = await UnitSdk.setParamValue(
      payload.id,
      payload.value,
      payload.serviceParamCode
    );
    return data;
  }),

  deleteUnit: thunk(async (actions, payload) => {
    return UnitSdk.delete(payload).then(() => {
      actions._storeDeleteUnit({ id: payload });
    });
  }),

  deleteUnits: thunk(async (actions, payload) => {
    return UnitSdk.bulkDelete(payload).then(() => {
      actions._storeDeleteUnits({ ids: payload });
    });
  }),

  cleanFilterUnit: thunk(async (actions, payload) => {
    await UnitSdk.clearFilter(payload.id);
  }),

  _storeCreateUnit: action((state, payload) => {
    if (state.allUnits[payload.id]) {
      state.allUnits[payload.id] = {
        ...state.allUnits[payload.id],
        ...payload.data,
      };
    } else {
      state.allUnits = { ...state.allUnits, [payload.id]: payload.data };
    }
  }),

  _storeUpdateUnit: action((state, payload) => {
    if (state.allUnits[payload.id]) {
      state.allUnits = {
        ...state.allUnits,
        [payload.id]: { ...state.allUnits[payload.id], ...payload.data },
      };
    }
  }),
  updateUnitsState: action((state, payload) => {
    _.assign(state.allUnits, payload);
  }),
  _storeALlUnits: action((state, payload) => {
    // will fix from BE we did not get the schedules with the respons when we get units v2/units
    const oldUnits = { ...state.allUnits };
    Object.keys(payload).forEach(
      unitId => (payload[unitId] = { ...oldUnits[unitId], ...payload[unitId] })
    );
    state.allUnits = payload;
  }),

  _storeDeleteUnit: action((state, payload) => {
    delete state.allUnits[payload.id];
  }),

  _storeDeleteUnits: action((state, payload) => {
    payload?.ids?.forEach(id => {
      delete state.allUnits[id];
    });
  }),

  setUnitActiveOperationMode: thunk((actions, payload) => {
    const { unitId, data: mode } = payload;
    return UnitSdk.setActiveOperationMode(unitId, +mode as number);
  }),
  setUnitActiveOperationStatus: thunk(async (actions, payload) => {
    const { unitId, data: operationStatus } = payload;
    await UnitSdk.setActiveOperationStatus(unitId, +operationStatus as number);
    actions._storeUpdateUnit({
      id: unitId,
      data: { activeOperationStatus: +operationStatus },
    });
  }),

  setUnitsActiveOperationStatus: thunk(
    async (actions, payload, { getStoreState }) => {
      const { units, operationStatus } = payload;
      UnitSdk.setUnitsActiveOperationStatus(
        units,
        +operationStatus as number
      ).then(() => {
        const storeState: any = getStoreState();
        const allUnits: any = storeState?.units?.allUnits;
        for (let unitId of units) {
          allUnits[unitId] = {
            ...allUnits[unitId],
            activeOperationStatus: operationStatus,
          };
        }
        actions.initialize(allUnits);
      });
    }
  ),
  setUnitActiveSwingMode: thunk(async (actions, payload) => {
    const { unitId, data: mode } = payload;
    await UnitSdk.setActiveSwingMode(unitId, +mode as number);
    actions._storeUpdateUnit({
      id: unitId,
      data: { activeSwingMode: +mode },
    });
  }),
  setUnitActiveFanMode: thunk(async (actions, payload) => {
    const { unitId, data: mode } = payload;
    await UnitSdk.setActiveFanMode(unitId, +mode as number);
    actions._storeUpdateUnit({
      id: unitId,
      data: { activeFanMode: +mode },
    });
  }),
  setUnitActiveSetpoint: thunk((actions, payload) => {
    const { unitId, data: setpoint } = payload;
    return UnitSdk.setActiveSetpoint(unitId, +setpoint as number);
  }),
  changePowerState: thunk((actions, payload, { injections }) => {
    const { unitId, state } = payload;
    return UnitSdk.setActiveOperationStatus(unitId, state).then(() => {
      actions.updateUnitLocally({
        id: unitId,
        unit: { activeOperationStatus: state },
      });
    });
  }),
  getAndReplaceUnits: thunk(async (actions, state) => {
    const allUnits = await UnitSdk.getUnits();
    actions.initialize(allUnits);
  }),
  createOtherUnit: thunk(async (actions, payload) => {
    const unit = await DeviceSdk.createOtherUnit(payload.deviceId, {
      ...payload,
      deviceId: undefined,
    });
    unit && actions._storeCreateUnit({ id: unit.id, data: unit });
  }),
  getUnitParamsAndStats: thunk((actions, payload) => {
    const { unitId, startTime, endTime, params, isReduced } = payload;
    return UnitSdk.getUnitParamsAndStats(
      unitId,
      startTime,
      endTime,
      params,
      isReduced
    );
  }),
  getUnitCategories: thunk(async (actions, payload) => {
    const response = await UnitSdk.getUnitCategories(payload);
    return response;
  }),
  getUnitCategoryParams: thunk(async (actions, payload) => {
    const { unitId, category } = payload;
    const response = await UnitSdk.getUnitCategoryParams(unitId, category);
    return response;
  }),
  updateUnitParamsValues: thunk(async (actions, payload) => {
    const { id, data } = payload;
    const response = await UnitSdk.updateUnitParamsValues(id, data);
    return response;
  }),
  getUnitCategoryParamsByParams: thunk(async (actions, payload) => {
    const { unitId, params } = payload;
    const response = await UnitSdk.getUnitCategoryParamsByParams(
      unitId,
      params
    );
    return response;
  }),
  getSensorsAndPowerMetersStats: thunk((actions, payload) => {
    return UnitSdk.getSensorsAndPowerMetersStats(payload);
  }),
  storeUpdateUnitsZones: action((state, payload) => {
    const unit = state.allUnits[payload.unitId];
    if (!unit) {
      return;
    }
    if (payload.add) {
      if (unit.zones[payload.zone.id]) {
        delete unit.zones[payload.zone.id];
      }

      unit.zones[payload.zone.id] = payload.zone;
    } else {
      delete unit.zones[payload.zone.id];
    }
  }),
  assoControlAndService: thunk((actions, payload) => {
    if (!!payload.controlId) {
      return UnitSdk.associateControlUnitToServiceUnit(
        payload.srvId,
        payload.controlId
      );
    }
    return UnitSdk.dissociateControlUnitFromServiceUnit(payload.srvId);
  }),
  getUnitsByWithHiddenUnits: computed(
    [state => state.allUnits, state => state.getUnitAffiliation],
    (allUnits, getUnitAffiliation) =>
      memo((entityType, entityId, options = { type: "all", subType: null }) => {
        // entityId of undefined, null or '' gets all units
        if (_.isNil(entityId) || entityId.length === 0) {
          return Object.values(allUnits);
        } else {
          return Object.values(allUnits).filter(unit => {
            // Apply filter from options
            // Unit types: 2 - indoor service, 2 - outdoor
            if (options?.type === "indoor" && !unit.isVisible) {
              return false;
            }
            if (
              options?.type &&
              ((options.type === "indoor" && unit.type !== 1) ||
                (options.type === "outdoor" && unit.type !== 2) ||
                (options.type === "service" && unit.type !== 3))
            ) {
              return false;
            }

            if (options.subType !== null && options.subType !== unit.subType) {
              return false;
            }
            // Apply filter by entity type
            const unitAff = getUnitAffiliation(unit.id);

            switch (entityType) {
              case "customer":
                return entityId === unitAff.customerId;
              case "site":
                return entityId === unitAff.siteId;
              case "system":
                return entityId === unitAff.systemId;
            }
          });
        }
      }, 100)
  ),
  getFilteredUnits: computed([state => state.allUnits], allUnits =>
    memo(
      (
        entityType,
        entityId,
        options = { type: "all" },
        renameServiceUnits = false
      ) => {
        const units: any = [];

        Object.values(allUnits).forEach(unit => {
          if (
            options?.type &&
            ((options.type === "indoor" && unit.type !== 1) ||
              (options.type === "outdoor" && unit.type !== 2) ||
              (options.type === "service" && unit.type !== 3))
          ) {
            return;
          }

          if (entityType === "customer" && entityId !== unit.customer) {
            return;
          }

          if (entityType === "site" && entityId !== unit.site) {
            return;
          }

          if (entityType === "system" && entityId !== unit.system) {
            return;
          }
          const item = { ...unit };
          let unitName: string = item?.name;

          if (renameServiceUnits && +item?.type === 3) {
            // is service unit
            const controlUnitId: any = item.controlUnit;
            const controlName = allUnits[controlUnitId]?.name || `Unassigned`;
            unitName = `${controlName} (${item.address})`;
          }
          item.name = unitName;
          units.push(item);
        });
        return units;
      },
      100
    )
  ),

  getUnitInfo: computed(
    [
      state => state.allUnits,
      (state, storeState) => storeState.systems.allSystems,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.customers.allCustomers,
    ],
    (allUnits, allSystems, allDevices, allSites, allCustomers) =>
      memo(id => {
        const unit = allUnits[id];
        let system;
        if (unit.system) {
          system = allSystems[unit.system];
        }
        const unitDevice = allDevices[unit.device];
        const unitSite = allSites[unitDevice.site];
        const unitCustomer = allCustomers[unitSite.customer];

        return {
          unit,
          system,
          device: unitDevice,
          site: unitSite,
          customer: unitCustomer,
        };
      }, 100)
  ),
  updateUnitsAndSchedules: thunk(async (actions, payload) => {
    if (payload.updatedData.units.length) {
      const data = await UnitSdk.updateUnitsAndSchedules(payload.updatedData);
      const units: IUnit[] = Object.values(data);

      for (let unit of units) {
        actions._storeUpdateUnit({ id: unit.id, data: unit });
      }
    }
  }),
  getEWrcLock: thunk(async (actions, payload) => {
    const data = await UnitSdk.getEWrcLock(payload.id);
    return data;
  }),
  setEWrcLock: thunk(async (actions, payload) => {
    const data = await UnitSdk.setEWrcLock(payload.id, payload.data);
    return data;
  }),
  setManyEWrcLocks: thunk(async (actions, payload) => {
    const data = await UnitSdk.setManyEWrcLocks(payload.data);
    return data;
  }),
  toggleEWrcLock: thunk(async (actions, payload) => {
    return UnitSdk.toggleEWrcLocks(payload).then(() => {
      actions.updateUnitLocally({
        id: payload.units[0],
        unit: { eWrcDisable: payload.isEWrcDisable },
      });
    });
  }),
  createCustomParam: thunk(async (actions, payload) => {
    const data = await UnitSdk.createCustomParam(payload);
    return data;
  }),
  updateCustomParam: thunk(async (actions, payload) => {
    const data = await UnitSdk.updateCustomParam(payload.id, payload.data);
    return data;
  }),
  deleteCustomParam: thunk(async (actions, payload) => {
    const data = await UnitSdk.deleteCustomParam(payload);
    return data;
  }),
  getUnitStatistics: thunk((actions, payload) => {
    const { unitId, startTime, endTime, size } = payload;
    return UnitSdk.getUnitStats(unitId, startTime, endTime, size);
  }),
  fetchUnitDiagByParams: thunk((actions, payload) => {
    const { unitId, startTime, endTime, params } = payload;
    return UnitSdk.getUnitStatsByParams(unitId, startTime, endTime, params);
  }),
  updateWaterHeaterDHWTankSwitch: thunk((actions, payload) => {
    return UnitSdk.updateWaterHeaterDHWTankSwitch(payload.id, payload.data);
  }),
  getUnitsFromStore: thunk((actions, payload, { getState }) => {
    return getState()?.allUnits || {};
  }),
  updateWaterHeaterDHWTankSetpoint: thunk((actions, payload) => {
    return UnitSdk.updateWaterHeaterDHWTankSetpoint(payload.id, payload.data);
  }),
  updateWaterHeaterMode: thunk((actions, payload) => {
    return UnitSdk.updateWaterHeaterMode(payload.id, payload.data);
  }),
  updateWaterHeaterBooster: thunk((actions, payload) => {
    return UnitSdk.updateWaterHeaterBooster(payload.id, payload.data);
  }),
  updateWaterHeaterCoolingSetpoint: thunk((actions, payload) => {
    return UnitSdk.updateWaterHeaterCoolingSetpoint(payload.id, payload.data);
  }),
  updateWaterHeaterHeatingSetpoint: thunk((actions, payload) => {
    return UnitSdk.updateWaterHeaterHeatingSetpoint(payload.id, payload.data);
  }),
  updateServiceUnitsForMultiUnits: action((state, payload) => {
    const updatedUnits: any = state.allUnits;
    payload.map(
      (unit: any) => (updatedUnits[unit.id].serviceUnits = unit.serviceUnits)
    );
    state.allUnits = updatedUnits;
  }),
  updateMultipleUnitsLocally: thunk(
    async (actions, payload, { getStoreState }) => {
      const storeState: any = getStoreState();

      const allUnits: any = storeState?.units?.allUnits;

      for (let unitId in allUnits) {
        if (allUnits[unitId][payload.filter]?.indexOf(payload.filterVal) > -1) {
          allUnits[unitId] = {
            ...allUnits[unitId],
            [payload.key]: payload.value,
          };
        }
      }

      actions.initialize(allUnits);
    }
  ),
  storeUpdateUnitsArrayZones: action((state, payload) => {
    const allUnits = state.allUnits;
    const { add, units, zone } = payload;

    Object.values(allUnits).forEach((unit: any) => {
      if (!_.isEmpty(unit.zones) && unit.zones[0] === zone.id) {
        allUnits[unit.id].zones = [];
      }
    });
    units.forEach((unitId: string) => {
      const unit = allUnits[unitId];
      if (!unit) {
        return;
      }

      allUnits[unitId].zones = [zone.id];
    });
    state.allUnits = { ...allUnits };
  }),
  updateUnitSystem: thunk((actions, payload, { injections }) => {
    const { unitId, oldSystem, newSystem } = payload;
    const { sdkSystem } = injections;

    if (!oldSystem) {
      return sdkSystem.addUnit(newSystem, unitId);
    } else if (!newSystem) {
      return sdkSystem.removeUnit(oldSystem, unitId);
    }
    return sdkSystem.removeUnit(oldSystem, unitId).then(() => {
      return sdkSystem.addUnit(newSystem, unitId);
    });
  }),
  getOnUnitsByMode: thunk((actions, payload) => {
    return UnitSdk.getOnUnitsByMode(payload);
  }),
  getAllOnUnits: thunk((actions, payload) => {
    return UnitSdk.getAllOnUnits(payload);
  }),
  setUnitLocally: action((state, payload) => {
    if (state.allUnits[payload?.id]) {
      state.allUnits[payload.id] = {
        ...state.allUnits[payload.id],
        ...payload,
      };
    }
  }),
};
