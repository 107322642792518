import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  editInPlace: {
    position: 'relative',
    paddingRight: '72px',
  },
  actions: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
  },
}));

export default useStyles;
