import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  disconnectedDevice: {
    background: 'rgba(240, 81, 70, 0.2)'
  },
  TableCell: {
    ...theme.tables.tableCell,
    padding: '10px 15px',
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    '&>span:first-child': {
      marginRight: '10px',
    }
  },
  mediumWidth: {
    width: '200px !important',
    maxWidth: '200px !important',
  },
  smallWidth: {
    width: '50px !important',
    maxWidth: '50px !important',
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
  },
  disconnectAlertBody: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.41px',
    textAlign: 'left',
  },
  inputRoot: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    height: '25px',
    fontSize: '14px',
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '50px',
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'calc(100% - 25px)',
  },
  lineColumnCell:{
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
    minWidth:75
  },
  statusCell: {
    color: '#545964',
  },
  connectedDevice: {
    color: '#5FBA82',
  }
}));
export default useStyles;
