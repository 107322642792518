import { Event as sdkEvent } from "coolremote-sdk";

import {
  Action,
  action,
  actionOn,
  ActionOn,
  Computed,
  computed,
  createTypedHooks,
  Thunk,
  thunk,
} from "easy-peasy";
import { isUndefined, isEmpty } from "lodash";
import { t } from "ttag";
import walkMe from "../services/walkMe";
import { alertActions, IAlertActions } from "./actions/AlertActions";
import { deviceActions, IDeviceActions } from "./actions/DeviceActions";
import { ISiteActions, siteActions } from "./actions/SiteActions";
import { ISystemActions, systemActions } from "./actions/SystemActions";
import { ITriggerActions, triggerActions } from "./actions/TriggerActions";
import { IUnitActions, unitActions } from "./actions/UnitActions";
import { IUserActions, userActions } from "./actions/UserActions";
import { alertGroupsModel, IAlertGroupsModel } from "./AlertGroups";
import { alertsModel, IAlertsModel } from "./Alerts";
import {
  anomalyTemplatesModel,
  IAnomalyTemplatesModel,
} from "./AnomalyTemplates";
import { auditsModel, IAuditsModel } from "./Audits";
import { compressorsModel, ICompressorsModel } from "./Compressors";
import { contactsModel, IContactsModel } from "./Contacts";
import { customersModel, ICustomersModel } from "./Customers";
import { devicesModel, IDevicesModel } from "./Devices";
import { IMessagesModel, messageModel } from "./ErrorMessage";
import { featuresModel, IFeaturesModel } from "./Features";
import { filesModel, IFilesModel } from "./Files";
import { generalReportsModel, IGeneralReportsModel } from "./GeneralReports";
import { groupsModel, IGroupsModel } from "./Groups";
import { IInvitesModel, invitesModel } from "./Invites";
import { ILoaderModel, loaderModel } from "./loader";
import { IMessageHandlers, messageHandlers } from "./MessageHandlers";
import { INoteModel, notesModel } from "./Notes";
import {
  IOperationAutomateModel,
  OperationAutomateModel,
} from "./OperationAutomate";
import { IPowerMetersModel, powerMetersModel } from "./powerMeters";
import { IReportsData, ReportsDataModel } from "./ReportsData";
import {
  IScheduledReportsModel,
  scheduledReportsModel,
} from "./ScheduledReports";
import { IScheduleModel, scheduleModel } from "./Schedules";
import { IScriptModel, scriptModel } from "./Scripts";
import { ISelectionsModel, selectionsModel } from "./Selections";
import { ISensorsModel, sensorsModel } from "./Sensors";
import { ISitesModel, sitesModel } from "./Sites";
import { ISystemsModel, systemsModel } from "./Systems";
import { ITimersModel, timersModel } from "./Timers";
import { ITrapsModel, trapsModel } from "./traps";
import { ITriggersModel, triggersModel } from "./Triggers";
import { IUnitsModel, unitsModel } from "./Units";
import { IUsersModel, usersModel } from "./Users";
import { IZonesModel, zonesModel } from "./Zones";
import { getHostname } from "../services/utils";
import { bacnetDevicesModel, IBacnetDevicesModel } from "./BacnetDevices";

const msgsToLogout: any = {
  "Invalid credentials": true,
  "User associated with token not found": true,
  "Pre-paid session quota exceeded": true,
  "Pre-paid session length must be defined": true,
  "Token verification failed": true,
  "Missing token": true,
  "user was not found by token": true,
  "failed to decode token": true,
  "User is Expired": true,
};

// TODO: move IServiceParams and IServiceTypes into separate models?
// Or make one IServices model for all of them?
export interface IServiceParams {
  [key: string]: any;
}

export interface IServiceTypes {
  temperatureScale?: any;
  operationStatuses?: any;
  operationModesWithTemperatures?: any;
  fanModes?: any;
  swingModes?: any;
  weekDays?: any;
  roles?: any;
  permissions?: any;
  resources?: any;
  hvacBrands?: any[];
  outdoorUnitTasks?: any;
  systemTypes?: string[];
  alertClasses?: any;
  alertClearTypes?: any;
  proParsingCodes?: any;
  capacityMeasurementUnitTypes?: any;
  unitTypes?: any;
  unitSubTypes?: any;
  systemUnitTypes?: any;
  eventClearTypes?: any;
  eventTypes?: any;
  eventStatusTypes?: any;
  measurementUnitTypes?: any;
  pressureScale?: any;
  languages?: any;
  procedureStepTypes?: any;
  procedureDeviceCommands?: any;
  procedureConditions?: any;
  procedureRunningStates?: any;
  procedureStateCommands?: any;
  actionSources?: any;
  procedureRunResultTypes?: any;
  procedureStepResultTypes?: any;
  [index: string]: any | undefined;
  timeFormat?: any;
  dateFormat?: any;
  deviceTypes?: any;
  scheduledReportTypes?: any;
}

interface OperatorOption {
  value: string;
  label: string;
}

type StringMap = {
  [key: string]: string;
};

export interface IRootStoreModel
  extends IUserActions,
  ISiteActions,
  IDeviceActions,
  IUnitActions,
  ISystemActions,
  ITriggerActions,
  IAlertActions,
  IMessageHandlers {
  customers: ICustomersModel;
  timers: ITimersModel;
  sites: ISitesModel;
  reports: IGeneralReportsModel;
  devices: IDevicesModel;
  alerts: IAlertsModel;
  triggers: ITriggersModel;
  zones: IZonesModel;
  ReportsData: IReportsData;
  units: IUnitsModel;
  sensors: ISensorsModel;
  operationAutomate: IOperationAutomateModel;
  contacts: IContactsModel;
  compressors: ICompressorsModel;
  systems: ISystemsModel;
  scripts: IScriptModel;
  users: IUsersModel;
  features: IFeaturesModel;
  alertGroups: IAlertGroupsModel;
  audits: IAuditsModel;
  selections: ISelectionsModel;
  serviceParams: IServiceParams;
  types: IServiceTypes;
  schedules: IScheduleModel;
  serviceParamTypes: any;
  serviceErrorTypes: any;
  traps: ITrapsModel;
  anomalyTemplates: IAnomalyTemplatesModel;
  config: any;
  isLoggedIn: boolean;
  isUsernameNotEmail: boolean;
  currentUserEmail: string;
  isInitialized: boolean;
  unitUpdateStatus: string;
  errorMessage: IMessagesModel;
  loader: ILoaderModel;
  trapOperatorsOptions: any;
  thresholdOptions: any;
  checkOperatorsOptions: any;
  enumOperatorsOptions: OperatorOption[];
  operatorTypesMap: StringMap;
  invites: IInvitesModel;
  files: IFilesModel;
  groups: IGroupsModel;
  powerMeters: IPowerMetersModel;
  bacnetDevices: IBacnetDevicesModel;
  scheduledReports: IScheduledReportsModel;
  notes: INoteModel;
  importExcel: Thunk<IRootStoreModel, { data: any }>;
  doLogin: Thunk<IRootStoreModel, { username: string; password: string }>;
  doImpersonate: Thunk<
    IRootStoreModel,
    { username: string; adminname: string; password: string }
  >;
  deleteMe: Thunk<IRootStoreModel, string | void>;
  doLogout: Thunk<IRootStoreModel, string | void>;
  getUserTree: Thunk<IRootStoreModel, boolean | void, any, IRootStoreModel>;
  getUserTreeMobile: Thunk<
    IRootStoreModel,
    boolean | void,
    any,
    IRootStoreModel
  >;
  followUpLoading: Thunk<IRootStoreModel, boolean | void, any, IRootStoreModel>;
  followUpLoadingMobile: Thunk<
    IRootStoreModel,
    boolean | void,
    any,
    IRootStoreModel
  >;
  openWebsocket: Thunk<IRootStoreModel, boolean, any, IRootStoreModel>;
  setLoggedIn: Action<IRootStoreModel, boolean>;
  setIsUsernameNotEmail: Action<IRootStoreModel, boolean>;
  setCurrentEmail: Action<IRootStoreModel, string>;
  setEnableErrorPage: Action<IRootStoreModel, boolean>;
  enableErrorPage: boolean;
  setLogOut: Action<IRootStoreModel>;
  setUnitUpdateStatus: Action<IRootStoreModel, { status: string }>;
  fetchedUserTree: Action<IRootStoreModel, boolean | void>;
  resetCallBacks: Action<IRootStoreModel>;
  setCallBacks: Action<IRootStoreModel, { messageName: any; func: any }>;
  setServiceParams: Action<IRootStoreModel, IServiceParams>;
  updateServiceParams: Action<IRootStoreModel, { param: any; code: string }>;
  deleteServiceParams: Action<IRootStoreModel, string>;
  setTypes: Action<IRootStoreModel, IServiceTypes>;
  doStatsUpdate: boolean;
  setStatsUpdate: Action<IRootStoreModel, boolean>;
  unitTypesMirrror: Computed<IRootStoreModel>;
  getDevicesTypesNames: Computed<
    IRootStoreModel,
    (deviceType: number, deviceBrand?: any) => string,
    IRootStoreModel
  >;
  scheduleTypesMirror: Computed<IRootStoreModel>;
  eventClearTypesMirror: Computed<IRootStoreModel>;
  eventTypesMirror: Computed<IRootStoreModel>;
  eventStatusTypesMirror: Computed<IRootStoreModel>;
  customParameterOperationsOptions: Computed<IRootStoreModel>;
  waterHeaterOperationStatusesMirror: Computed<IRootStoreModel>;
  waterHeaterOperationModesMirror: Computed<IRootStoreModel>;
  unitTypesOptions: Computed<IRootStoreModel>;
  adminLogin: Thunk<IRootStoreModel, string>;
  getObject: Computed<
    ISelectionsModel,
    (objType: string, objId: string) => any,
    IRootStoreModel
  >;
  parentsMap: {
    sites: any;
    systems: any;
    devices: any;
    units: any;
  };
  setParentsMap: Action<IRootStoreModel, any>;
  setServiceParamTypes: Action<IRootStoreModel, any>;
  setServiceErrorTypes: Action<IRootStoreModel, any>;
  selectedAlert: any;
  setSelectedAlert: Action<IRootStoreModel, any>;
  isWalkMeLoaded: boolean;
  walkMeInit: Action<IRootStoreModel>;
  appVersion: string;
  setAppVersion: Action<IRootStoreModel, string>;
  compareAppVersion: Action<IRootStoreModel, string>;
  operationModesMirror: Computed<IRootStoreModel>;
  customEnumOptionsForAutomationRules: Computed<IRootStoreModel>;
  fanModesMirror: Computed<IRootStoreModel>;
  swingModesMirror: Computed<IRootStoreModel>;
  operationStatusesMirror: Computed<IRootStoreModel>;
  temperatureScaleMirror: Computed<IRootStoreModel>;
  serviceParamTypesOptions: Computed<IRootStoreModel>;

  getFullTree: Thunk<IRootStoreModel>;
  repullData: Thunk<IRootStoreModel>;
  procedureRunningStatesMirror: Computed<IRootStoreModel>;
  stepsOptions: Computed<IRootStoreModel>;
  commandsOptions: Computed<IRootStoreModel>;
  conditionsOptions: Computed<IRootStoreModel>;
  callBacks: any;
  procedureStatuses: Computed<IRootStoreModel>;
  setDropDownNavigation: Action<IRootStoreModel, string>;
  isDropDownOpen: any;
  reportResults: Computed<IRootStoreModel>;
  stepResultStatus: Computed<IRootStoreModel>;
  stepsTypesMirror: Computed<IRootStoreModel>;
  theme: any;
  prepareTheme: Thunk<IRootStoreModel>;
  setThemeFile: Action<IRootStoreModel, any>;
  setConfigFile: Action<IRootStoreModel, any>;
  ruleParameters: any;
  unitSubTypesMirror: Computed<IRootStoreModel>;
  resetPulling: number;
  onUpdateUnits: ActionOn<IRootStoreModel, IRootStoreModel>;
  customParameterOperationsOptionsObject: Computed<IRootStoreModel>;
  stillLoadingInitialAPIs: boolean;
  setStillLoadingInitialAPIs: Action<IRootStoreModel, boolean>;
  getTypes: Thunk<IRootStoreModel>;
  getServiceParams: Thunk<IRootStoreModel>;
  getEntityNameById: Computed<
    IRootStoreModel,
    (id: string) => string | undefined
  >;
  verify2FA: Thunk<IRootStoreModel, { code: string }>;
  sitePackageMirror: Computed<IRootStoreModel, any>;
  wsEventListeners: any;
  addWebSocketListener: Action<IRootStoreModel, any>;
  removeWebSocketListener: Action<IRootStoreModel, any>;
}



/**
 * A function that shows an error message with the ability to send a report.
 * Displays an error message and reports it if `showErrorMessages` is true.
 *
 */
const showErrorMessageWithReport = ({messageStoreActions,showErrorMessages,messageDescription,errorRef = null,mainAction}:any):void => {

  const GENERIC_ERROR_MESSAGE = 'We are experiencing delays in loading this page. Please try to reloading page through the browser reload/refresh button.'

  const {setGlobalErrorReport,addMessage} = messageStoreActions

  if(!showErrorMessages){
    return
  }
  setGlobalErrorReport({ messageDescription, errorRef })
  addMessage({
    message: GENERIC_ERROR_MESSAGE,
    buttonText: "Reload",
    mainAction,
    secondaryText: "Ignore",
    allowDialogClose: false,
  });

}

const RootStoreModel: IRootStoreModel = {
  stillLoadingInitialAPIs: true,
  customers: customersModel,
  sites: sitesModel,
  devices: devicesModel,
  alerts: alertsModel,
  triggers: triggersModel,
  zones: zonesModel,
  ReportsData: ReportsDataModel,
  units: unitsModel,
  sensors: sensorsModel,
  operationAutomate: OperationAutomateModel,
  reports: generalReportsModel,
  contacts: contactsModel,
  compressors: compressorsModel,
  systems: systemsModel,
  users: usersModel,
  features: featuresModel,
  invites: invitesModel,
  notes: notesModel,
  alertGroups: alertGroupsModel,
  scheduledReports: scheduledReportsModel,
  groups: groupsModel,
  powerMeters: powerMetersModel,
  bacnetDevices: bacnetDevicesModel,
  audits: auditsModel,
  traps: trapsModel,
  schedules: scheduleModel,
  anomalyTemplates: anomalyTemplatesModel,
  selections: selectionsModel,
  loader: loaderModel,
  files: filesModel,
  serviceParams: [],
  scripts: scriptModel,
  types: { unitTypes: {} },
  errorMessage: messageModel,
  timers: timersModel,
  serviceParamTypes: {},
  serviceErrorTypes: {},
  callBacks: {},
  theme: null,
  config: null,
  isWalkMeLoaded: false,
  walkMeInit: action(state => {
    if (state.isWalkMeLoaded) {
      return;
    }
    walkMe();
    state.isWalkMeLoaded = true;
  }),
  resetPulling: 0,
  isLoggedIn: true, // Optimistic default
  isUsernameNotEmail: false,
  currentUserEmail: "",
  isInitialized: false,
  unitUpdateStatus: "",
  doStatsUpdate: false,
  parentsMap: { sites: {}, devices: {}, systems: {}, units: {} },
  appVersion: "",
  setAppVersion: action((state, payload) => {
    if (!payload) {
      return;
    }
    state.appVersion = payload;
    return;
  }),
  compareAppVersion: action((state, payload) => {
    if (!payload) {
      return;
    }
    if (!state.appVersion) {
      state.appVersion = payload;
      return;
    }
    if (state.appVersion && state.appVersion !== payload) {
      window.location.reload();
    }
  }),
  setParentsMap: action((state, payload) => {
    state.parentsMap = payload;
  }),
  setStatsUpdate: action((state, payload) => {
    state.doStatsUpdate = payload;
  }),
  selectedAlert: null,
  setSelectedAlert: action((state, payload) => {
    state.selectedAlert = payload;
  }),
  // Initialize WebSocket event listeners array
  wsEventListeners: [],
  // Add listener action
  addWebSocketListener: action((state, listener) => {
    state.wsEventListeners.push(listener);
  }),
  // Remove listener action
  removeWebSocketListener: action((state, listener) => {
    state.wsEventListeners = state.wsEventListeners.filter((l: any) => l !== listener);
  }),
  // Imported actions
  ...userActions,
  ...siteActions,
  ...deviceActions,
  ...unitActions,
  ...systemActions,
  ...triggerActions,
  ...alertActions,
  ...messageHandlers,
  unitTypesMirrror: computed(state => {
    const { types } = state;
    const { unitTypes } = types;

    if (!unitTypes) {
      return null;
    }

    const keys = Object.keys(unitTypes);
    const object: { [index: string]: any } = {};

    const unitType = keys.reduce((allTypes, key) => {
      const name = unitTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return unitType;
  }),
  scheduleTypesMirror: computed(state => {
    const { types } = state;
    const { scheduletypes } = types;

    if (!scheduletypes) {
      return null;
    }

    const keys = Object.keys(scheduletypes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = scheduletypes[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  eventClearTypesMirror: computed(state => {
    const { types } = state;
    const { eventClearTypes } = types;

    if (!eventClearTypes) {
      return null;
    }

    const keys = Object.keys(eventClearTypes);
    const object: { [index: string]: any } = {};

    const eventClearType = keys.reduce((allTypes, key) => {
      const name = eventClearTypes[key];
      allTypes[name as string] = key
        .replace(/trap/g, "Anomaly")
        ?.split(/(?=[A-Z])/)
        ?.map((word: string) => {
          // convert string like trapUpdated -> Anomaly Updated
          word = word[0]?.toUpperCase() + word?.slice(1);
          return word;
        })
        .join(" ");

      return allTypes;
    }, object);

    return eventClearType;
  }),
  eventTypesMirror: computed(state => {
    const { types } = state;
    const { eventTypes } = types;

    if (!eventTypes) {
      return {};
    }

    const keys = Object.keys(eventTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((allTypes, key) => {
      const name = eventTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return eventType;
  }),
  eventStatusTypesMirror: computed(state => {
    const { types } = state;
    const { eventStatusTypes } = types;

    if (!eventStatusTypes) {
      return {};
    }

    const keys = Object.keys(eventStatusTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((allTypes, key) => {
      const name = eventStatusTypes[key];
      allTypes[name as string] = key
        .split(/(?=[A-Z])/)
        ?.map((word: string) => {
          // convert string like autoAcknowledged -> Auto Acknowledged
          word = word[0]?.toUpperCase() + word?.slice(1);
          return word;
        })
        .join(" ");

      return allTypes;
    }, object);

    return eventType;
  }),
  sitePackageMirror: computed(state => {
    const { types = {} } = state;
    const { sitePackageEnums } = types;

    if (!sitePackageEnums) {
      return {};
    }

    const keys = Object.keys(sitePackageEnums);
    const object: { [index: string]: any } = {};

    const sitePackages = keys.reduce((allTypes, key) => {
      const name = sitePackageEnums[key];
      // mirror the site packages and 'splitThePackageName' into 'split The Package Name'
      allTypes[name as string] =
        key
          .match(/([A-Z]?[^A-Z]*)/g)
          ?.slice(0, -1)
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ") || "";

      return allTypes;
    }, object);

    return sitePackages;
  }),
  serviceParamTypesOptions: computed(state => {
    const { serviceParamTypes } = state;

    if (isEmpty(serviceParamTypes)) {
      return {};
    }

    const allOptions: any = {};

    Object.keys(serviceParamTypes).forEach((paramEnum: any) => {
      const options = serviceParamTypes[paramEnum];
      allOptions[paramEnum] = [];
      Object.keys(options).map((key: any) =>
        allOptions[paramEnum].push({ value: +key, label: options[key] })
      );
    });

    return allOptions;
  }),
  unitTypesOptions: computed(state => {
    const { types } = state;
    const { unitTypes } = types;

    if (!unitTypes) {
      return null;
    }

    return [
      { value: unitTypes.service, name: "Indoor" },
      { value: unitTypes.outdoor, name: "Outdoor" },
      { value: "mixed", name: "Mixed" },
    ];
  }),
  customParameterOperationsOptions: computed(state => {
    const { types } = state;
    const { customParameterOperations } = types;

    if (!customParameterOperations) {
      return [];
    }

    return [
      { value: customParameterOperations["+"], name: "+" },
      { value: customParameterOperations["-"], name: "-" },
      { value: customParameterOperations["*"], name: "*" },
      { value: customParameterOperations["/"], name: "/" },
    ];
  }),
  waterHeaterOperationStatusesMirror: computed(state => {
    const { types } = state;
    const { waterHeaterOperationStatusesEnum } = types;

    if (!waterHeaterOperationStatusesEnum) {
      return null;
    }

    const keys = Object.keys(waterHeaterOperationStatusesEnum);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = waterHeaterOperationStatusesEnum[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  waterHeaterOperationModesMirror: computed(state => {
    const { types } = state;
    const { waterHeaterModes } = types;

    if (!waterHeaterModes) {
      return null;
    }

    const keys = Object.keys(waterHeaterModes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = waterHeaterModes[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  trapOperatorsOptions: [
    {
      value: ">",
      label: ">",
    },
    {
      value: "<",
      label: "<",
    },
    { value: "=", label: "=" },
    {
      value: "!=",
      label: "≠",
    },
    {
      value: "threshold",
      label: "Difference",
    },
  ],
  doLogin: thunk(async (actions, payload, { injections }) => {
    const { sdkUser, sdkXhr } = injections;
    // localStorage.removeItem('token');
    sdkUser.logout();
    const loginResp = await sdkUser.connect(
      payload.username,
      payload.password,
      "CoolCommercial"
    );

    const { data, message } = loginResp;

    if (message) {
      return loginResp;
    }
    const { token, isUsernameNotEmail, currentUserEmail, is2FA, token2FA } =
      data || {};

    if (is2FA) {
      await sdkXhr.setToken(token2FA);
      return { is2FA };
    }

    if (!token) {
      return { message: "your session has been expired please login again" };
    }
    localStorage.setItem("token", token);
    await sdkXhr.setToken(token);
    actions.setLoggedIn(true);
    actions.setCurrentEmail(currentUserEmail);
    actions.setIsUsernameNotEmail(!!isUsernameNotEmail);
  }),

  verify2FA: thunk(async (actions, payload, { injections }) => {
    const { sdkUser, sdkXhr } = injections;

    const verify2FAResp = await sdkUser.verify2FA(
      payload.code,
      "CoolCommercial"
    );

    const { data, message } = verify2FAResp;

    if (message) {
      return verify2FAResp;
    }

    const { token, isUsernameNotEmail, currentUserEmail } = data || {};

    if (!token) {
      return { message: "your session has been expired please login again" };
    }

    localStorage.setItem("token", token);
    await sdkXhr.setToken(token);
    actions.setLoggedIn(true);
    actions.setCurrentEmail(currentUserEmail);
    actions.setIsUsernameNotEmail(!!isUsernameNotEmail);
  }),

  doImpersonate: thunk(async (actions, payload, { injections }) => {
    const { sdkUser, sdkXhr, sdkAdmin } = injections;
    sdkUser.logout();
    const { data, message } = await sdkUser.connect(
      payload.adminname,
      payload.password
    );

    if (message) {
      return { message };
    }

    const token = data.token;

    await sdkXhr.setToken(token);
    try {
      const userData = await sdkAdmin.getUserToken(payload.username);
      if (
        !userData ||
        !userData?.token ||
        typeof userData.token !== "string" ||
        userData.token.length === 0
      ) {
        return { message: "Something went wrong" };
      }
      localStorage.clear();
      localStorage.setItem("token", userData.token);
    } catch (error) {
      return { message: "Invalid username" };
    }
  }),

  deleteMe: thunk(async (actions, msg = "", { injections }) => {
    const { sdkUser } = injections;
    try {
      await sdkUser.deleteMe();
      await actions.doLogout();
    } catch (error) {
      throw error;
    }
  }),

  // logout the user
  doLogout: thunk(async (actions, msg = "", { injections }) => {
    const { sdkUser } = injections;
    actions.setLogOut();
    localStorage.removeItem("token");
    actions.setLoggedIn(false);
    msg && actions.errorMessage.addMessage({ message: msg });
    await sdkUser.closeWebSocket();
    return sdkUser.logout();
  }),
  importExcel: thunk(async (actions, payload) => { }),
  // get all initial data for the user
  getUserTreeMobile: thunk(
    async (
      actions,
      showErrorMessages = true,
      { getStoreState, getStoreActions, injections }
    ) => {
      const { sdkService } = injections;

      const initAPIs: any = [
        { API: actions.units.getUnits() },
        { API: actions.sensors.getAllSensorGroups() },
        { API: actions.devices.getDevices() },
        { API: actions.customers.getCustomers(), ref: "C" },
        { API: actions.sites.getSites(), ref: "S" },
        { API: actions.getTypes(), ref: "T" },
        { API: actions.users.getUserFlags(), ref: "UF" },
        { API: actions.users.getMe(), ref: "ME" },
        { API: actions.sites.getSiteFlags(), entity: "site flags", ref: "" },
        { API: actions.users.getUserPreferences() },
        { API: actions.systems.getAllSystemCustomized() },
      ];

      try {
        const resp: any = await Promise.allSettled(
          initAPIs.map(({ API }: any) => API)
        );
        let errorMsg = "";
        let logoutErrors = false;
        const errorsArray = [];

        for (let index in resp) {
          const result = resp[index];
          const { status, reason }: any = result;
          if (status === "rejected") {
            errorsArray.push(reason);
            if (index === "4" && String(reason.status)[0] === "4") {
              errorMsg = reason.message;
              logoutErrors = true;
              break;
            }

            if (reason.offline) {
              errorMsg =
                "Your internet connection is unstable, please try again later.";
              break;
            }

            if (reason.unknown) {
              errorMsg =
                "Something went wrong please reload the page. \n" +
                (reason.message || "");
              break;
            }
          }
        }

        if (logoutErrors) {
          actions.doLogout(errorMsg);
          return;
        }

        if (errorsArray.length && showErrorMessages) {
          if (errorMsg) {
            actions.errorMessage.addMessage({
              message: errorMsg,
              buttonText: "Reload",
              mainAction: () => actions.getUserTreeMobile(showErrorMessages),
              allowDialogClose: false,
            });
          } else {
            const token = localStorage.getItem("token");
            const errorRef = await sdkService.logAPIError({
              error: { token, error: errorsArray },
            });

            actions.errorMessage.addMessage({
              message: !errorRef
                ? "Oops… an error occurred.\nWe’re experiencing some problems accessing your account. Please try again later."
                : "Oops… an error occurred.\nWe’re experiencing some problems accessing your account. Please try again later.\n\nIf error persists please contact Administrator. \n" +
                "Error Reference: " +
                errorRef,
              buttonText: "Reload",
              mainAction: () => actions.getUserTreeMobile(showErrorMessages),
              allowDialogClose: false,
            });
          }
          return;
        }

        actions.fetchedUserTree();

        const types = resp[5].value;

        setTimeout(() => {
          actions.followUpLoadingMobile(showErrorMessages);
          sdkEvent
            .getEventsWithArrayParams({
              status: [
                types.eventStatusTypes["open"],
                types.eventStatusTypes["acknowledged"],
              ],
              type: [types.applications.management, types.applications.service],
            })
            .then((events: any) => actions.alerts.initialize({ events }))
            .catch(
              (e: any) =>
                showErrorMessages &&
                actions.errorMessage.addMessage({
                  message:
                    "Failed to fetch alerts." +
                    (e.message.includes("Failed to fetch data")
                      ? ""
                      : "\n" + e.message),
                })
            );
        }, 1000);
      } catch (e) {
        const error: any = e;
        actions.setEnableErrorPage(true);
        window.history.pushState("", "", "/error");
        const token = localStorage.getItem("token");
        const errorRef = await sdkService.logAPIError({
          error: { token, error },
        });

        showErrorMessages &&
          actions.errorMessage.addMessage({
            message:
              "Unexpected error: \n\nIf error persists please contact Administrator." +
              (errorRef ? "\nError Reference: " + errorRef : ""),
          });
      }
    }
  ),
  followUpLoadingMobile: thunk(
    async (actions, showErrorMessages = true, { injections }) => {
      const { sdkDevice, sdkSystem, sdkGroup, sdkService } = injections;

      const initAPIs: any = [
        { API: sdkSystem.getSystems(), entity: "systems", ref: "" },
        { API: sdkDevice.getMyLines(), entity: "", ref: "" },
        { API: sdkGroup.getGroups(), entity: "groups", ref: "" },
      ];

      try {
        const resp: any = await Promise.allSettled(
          initAPIs.map(({ API }: any) => API)
        );
        const [systems, lines, groups] = resp;

        actions.systems.initialize(systems.value || {});
        actions.devices.initializeLines(lines.value || {});
        actions.groups.initialize(groups.value || {});
        actions.setStillLoadingInitialAPIs(false);

        let errorMsg = "";
        let logoutErrors = false;
        let internetIssues = false;
        let noEntityError = false;
        let atLeastOneEntityError = false;
        let unexpectedFail = false;
        const errorsArray = [];

        for (let index in resp) {
          const result = resp[index];
          const { status, reason }: any = result;
          if (status === "rejected") {
            errorsArray.push(reason);
            if (msgsToLogout[reason.message]) {
              errorMsg = reason.message;
              logoutErrors = true;
              break;
            }

            if (reason.offline) {
              internetIssues = true; // connection not stable please reaload for better experince
            }

            if (reason.unknown) {
              unexpectedFail = true;
            }

            const apiData = initAPIs[index];
            const { entity = "" } = apiData || {};

            if (!entity) {
              noEntityError = true;
            } else {
              atLeastOneEntityError = true;
              errorMsg += errorMsg
                ? ", " + entity
                : "Failed to fetch " + entity;
            }
          }
        }

        if (logoutErrors) {
          actions.doLogout(errorMsg);
          return;
        }
        if (errorsArray.length && showErrorMessages) {
          if (noEntityError) {
            errorMsg += atLeastOneEntityError
              ? " and some other data."
              : "Failed to fetch some data.";
          }

          if (internetIssues) {
            // you may encounter some weird behavoirs please reload for better experince
            errorMsg +=
              "\n \n Your internet connection is unstable, please try again later.";
          } else if (unexpectedFail) {
            errorMsg +=
              "\n \n We are experiencing some difficulties fetching your data. Please try to reload this page when your internet connection is more stable.";
          }

          if (internetIssues) {
            actions.errorMessage.addMessage({
              message: errorMsg,
              secondaryText: "Ignore",
              mainAction: () =>
                actions.followUpLoadingMobile(showErrorMessages),
              buttonText: "Reload",
            });
          } else {
            const token = localStorage.getItem("token");
            const errorRef = await sdkService.logAPIError({
              error: { token, errorsArray },
            });

            actions.errorMessage.addMessage({
              message:
                errorMsg +
                (errorRef ? "\n Error Reference: " + errorRef : ""),
              secondaryText: "Ignore",
              mainAction: () =>
                actions.followUpLoadingMobile(showErrorMessages),
              buttonText: "Reload",
            });
          }
          return;
        }
      } catch (e) {
        const error: any = e;
        if (showErrorMessages) {
          if (error.offline) {
            showErrorMessages &&
              actions.errorMessage.addMessage({
                message:
                  "internet issues, please reload the page when your connection is more stable.",
                secondaryText: "Ignore",
                mainAction: () =>
                  actions.followUpLoadingMobile(showErrorMessages),
                buttonText: "Reload",
              });
          } else {
            const token = localStorage.getItem("token");
            const errorRef = await sdkService.logAPIError({
              error: { token, error },
            });

            showErrorMessages &&
              actions.errorMessage.addMessage({
                message:
                  "Something went wrong.." +
                  (errorRef ? "\n Error Reference: " + errorRef : ""),
                secondaryText: "Ignore",
                mainAction: () =>
                  actions.followUpLoadingMobile(showErrorMessages),
                buttonText: "Reload",
              });
          }
        }
      }
    }
  ),
  getUserTree: thunk(
    async (
      actions,
      showErrorMessages = true,
      { getStoreState, getStoreActions, injections }
    ) => {
      const { sdkService } = injections;

      //Load some calls asyn to not block the dashboard
      actions.units.getUnits();
      actions.sensors.getAllSensors();
      actions.sensors.getAllSensorGroups();
      actions.systems.getSystems();
      actions.systems.getAllSystemCustomized();

      //don't log in to the app if any of the following APIs has failed
      const initAPIs: any = [
        { API: actions.users.getUserFlags() },
        { API: actions.users.getMe() },
        { API: actions.customers.getCustomers() },
        { API: actions.sites.getSites() },
        { API: actions.getTypes() },
        { API: actions.sites.getSiteFlags() },
        { API: actions.devices.getDevices() },
        { API: actions.users.getUserPreferences() },
        { API: actions.getServiceParams() },
      ];

      try {
        const resp: any = await Promise.allSettled(
          initAPIs.map(({ API }: any) => API)
        );
        let logoutErrors = false;
        const errorsArray = [];
        let errorMsg = "";

        for (let index in resp) {
          const result = resp[index];
          const { status, reason }: any = result;
          if (status === "rejected") {
            errorsArray.push(reason);
            if (index === "2" && String(reason.status)[0] === "4") {
              errorMsg = reason.message;
              logoutErrors = true;
              break;
            }

            if (reason.offline) {
              errorMsg =
                "Your internet connection is unstable, please try again later.";
              break;
            }

            if (reason.unknown) {
              errorMsg =
                "Something went wrong please reload the page. \n" +
                (reason.message || "");
              break;
            }
          }
        }

        if (logoutErrors) {
          actions.doLogout(errorMsg);
          return;
        }

        if (errorsArray.length) {
          if (errorMsg) {

            showErrorMessageWithReport({
              messageStoreActions:actions.errorMessage,
              showErrorMessages,
              messageDescription:errorMsg,
              mainAction:() => actions.getUserTree(showErrorMessages)
            });

          } else {
            const token = localStorage.getItem("token");
            const errorRef = await sdkService.logAPIError({
              error: { token, error: errorsArray },
            });

            showErrorMessageWithReport({
              messageStoreActions:actions.errorMessage,
              showErrorMessages,
              messageDescription:'failed to fetch data.',
              errorRef,
              mainAction:() => actions.getUserTree(showErrorMessages)
            });
          }
          return;
        }

        const customersArray: any = Object.values(resp[2].value);
        const id: string = customersArray[0]?.id || "";

        sdkEvent
          .getEventsWithArrayParams({ status: [3, 1], type: [1, 3] })
          .then((events: any) => actions.alerts.initialize({ events }))
          .catch((e: any) => {
            const isGenericError = e.message.includes("Failed to fetch data")
            const message = `Failed to fetch alerts. ${!isGenericError ? e.message : ''}`;

            showErrorMessageWithReport({
              messageStoreActions:actions.errorMessage,
              showErrorMessages,
              messageDescription:message,
              mainAction:() => actions.getUserTree(showErrorMessages)
            });
          })
          .finally(() => {
            actions.fetchedUserTree();
            actions.followUpLoading(showErrorMessages);
          });
      } catch (e) {
        const error: any = e;
        actions.setEnableErrorPage(true);
        window.history.pushState("", "", "/error");

        const token = localStorage.getItem("token");
        const errorRef = await sdkService.logAPIError({
          error: { token, error },
        });

        showErrorMessageWithReport({
          messageStoreActions:actions.errorMessage,
          showErrorMessages,
          messageDescription:'Unexpected error',
          errorRef,
          mainAction:() => actions.getUserTree(showErrorMessages)
        });
      }
    }
  ),
  followUpLoading: thunk(
    async (
      actions,
      showErrorMessages = true,
      { getStoreState, getStoreActions, injections }
    ) => {
      const { sdkDevice, sdkService, sdkCompressor, sdkGroup } = injections;

      const initAPIs: any = [
        { API: sdkService.getServiceParamTypes(), entity: "" },
        { API: sdkService.getServiceErrorTypes(), entity: "" },
        { API: sdkCompressor.getCompressors(), entity: "compressors" },
        { API: sdkDevice.getMyLines(), entity: "" },
        { API: sdkGroup.getGroups(), entity: "groups" },
        { API: actions.powerMeters.getAllPowerMeters() },
      ];

      try {
        const resp: any = await Promise.allSettled(
          initAPIs.map(({ API }: any) => API)
        );
        const [paramsTypes, errorTypes, compressors, lines, groups] = resp;

        actions.compressors.initialize(compressors.value || {});
        // trueFalseEnum should be returnd with the response
        actions.setServiceParamTypes(
          { ...paramsTypes.value, trueFalseEnum: { 1: "TRUE", 0: "FALSE" } } ||
          {}
        );
        actions.setServiceErrorTypes(errorTypes.value || {});
        actions.devices.initializeLines(lines.value || {});
        actions.groups.initialize(groups.value || {});

        let logoutErrors = false;
        let internetIssues = false;
        let noEntityError = false;
        let atLeastOneEntityError = false;
        let unexpectedFail = false;
        let errorMsg = '';

        const errorsArray = [];

        for (let index in resp) {
          const result = resp[index];
          const { status, reason }: any = result;
          if (status === "rejected") {

            errorsArray.push(reason);

            if (msgsToLogout[reason.message]) {
              errorMsg = reason.message;
              logoutErrors = true;
              break;
            }

            if (reason.offline) {
              internetIssues = true; // connection not stable please reaload for better experince
            }

            if (reason.unknown) {
              unexpectedFail = true;
            }

            const apiData = initAPIs[index];
            const { entity = "" } = apiData || {};

            if (!entity) {
              noEntityError = true;
            } else {
              atLeastOneEntityError = true;
              errorMsg += errorMsg
                ? ", " + entity
                : "Failed to fetch " + entity;
            }
          }
        }

        if (logoutErrors) {
          actions.doLogout(errorMsg);
          return;
        }

        if (errorMsg) {
          if (noEntityError) {
            errorMsg += atLeastOneEntityError
              ? " and some other data."
              : "Failed to fetch some data.";
          }

          if (internetIssues) {

            errorMsg += "\n \n Your internet connection is unstable, please try again later.";

            showErrorMessageWithReport({
              messageStoreActions:actions.errorMessage,
              showErrorMessages,
              messageDescription:'Internet connection is unstable.'+ errorMsg,
              mainAction:() => actions.followUpLoading(showErrorMessages)
            });
          } else {
            const token = localStorage.getItem("token");
            const errorRef = await sdkService.logAPIError({
              error: { token, errorsArray },
            });

            if (unexpectedFail) {
              const errorMessage = errorMsg ?? 'Unexpected error';

              showErrorMessageWithReport({
                messageStoreActions:actions.errorMessage,
                showErrorMessages,
                messageDescription:errorMessage,
                mainAction:() => actions.followUpLoading(showErrorMessages)
              });
            }
          }
          return;
        }

      } catch (e) {
        const error: any = e;
        if (showErrorMessages) {
          if (error.offline) {

            showErrorMessageWithReport({
              messageStoreActions:actions.errorMessage,
              showErrorMessages,
              messageDescription:'user is Offline',
              mainAction:() => actions.followUpLoading(showErrorMessages)
            });

          } else {
            const token = localStorage.getItem("token");
            const errorRef = await sdkService.logAPIError({
              error: { token, error },
            });

            showErrorMessageWithReport({
              messageStoreActions:actions.errorMessage,
              showErrorMessages,
              messageDescription:"Something went wrong...",
              errorRef,
              mainAction:() => actions.followUpLoading(showErrorMessages)
            });

          }
        }
      }
    }
  ),
  openWebsocket: thunk(
    (actions, payload, { getStoreState, getStoreActions, injections }) => {
      const { sdkUser } = injections;
      const isMobile = payload;
      const rootActions = getStoreActions();

      sdkUser.openWebSocket(
        (message: any) => {
          if (message.type === "ping") {
            if (message.version) {
              let appVersion = getStoreState().appVersion;
              if (!appVersion) {
                actions.setAppVersion(message.version);
                appVersion = message.version;
              }

              if (appVersion !== message.version) {
                window.location.reload();
              }
            }
            return;
          }

          const { data, name } = message;
          if (!data) {
            return;
          }

          // Notify all WebSocket event listeners
          const state = getStoreState();
          state.wsEventListeners.forEach((listener: (message: any) => void) => {
            listener(message);
          });

          const selectedSite =
            getStoreState().selections[
              isMobile ? "mobileSelections" : "selections"
            ].siteId;
          const isSelectedSite =
            selectedSite === "groups" || data.site === selectedSite;

          if (name === "UPDATE_UNIT") {
            const storeUnitObj: any = getStoreState().units.allUnits[data.unitId];

            if (!isSelectedSite || !storeUnitObj) {
              return;
            }

            const unitId = data.unitId;
            const unitObj: any = {};

            if ("name" in data) {
              unitObj.name = data.name;
            }

            if (+storeUnitObj?.activeOperationMode === 7) {
              if ("realActiveOperationMode" in data) {
                unitObj.realActiveOperationMode = data.realActiveOperationMode;
              }
            }
            else {
              if ("operationMode" in data) {
                unitObj.activeOperationMode = data.operationMode;
              }
            }


            if ("operationStatus" in data) {
              unitObj.activeOperationStatus = data.operationStatus;
            }

            if ("setpoint" in data) {
              unitObj.activeSetpoint = data.setpoint;
            }

            if ("fan" in data) {
              unitObj.activeFanMode = data.fan;
            }

            if ("ambientTemperature" in data) {
              unitObj.ambientTemperature = data.ambientTemperature;
            }

            if ("swing" in data) {
              unitObj.activeSwingMode = data.swing;
            }

            if ("filter" in data) {
              unitObj.filter = data.filter;
            }

            if ("message" in data) {
              unitObj.message = data.message;
            }

            getStoreActions().units.updateUnitLocally({
              id: unitId,
              unit: unitObj,
            });
            return;
          }

          if (name === "WATER_HEATER_UPDATED") {
            if (!isSelectedSite) {
              return;
            }
            const unitId = data.unitId;
            const unitObj: any = {};
            if ("name" in data) {
              unitObj.name = data.name;
            }
            if ("heaterHeatingSP" in data) {
              unitObj.heaterHeatingSP = data.heaterHeatingSP;
            }
            if ("heaterCoolingSP" in data) {
              unitObj.heaterCoolingSP = data.heaterCoolingSP;
            }
            if ("heaterMode" in data) {
              unitObj.heaterMode = data.heaterMode;
            }
            if ("heaterTankSP" in data) {
              unitObj.heaterTankSP = data.heaterTankSP;
            }
            if ("heaterTankONOFF" in data) {
              unitObj.heaterTankONOFF = data.heaterTankONOFF;
            }
            if ("heaterTankTemp" in data) {
              unitObj.heaterTankTemp = data.heaterTankTemp;
            }
            if ("heaterBooster" in data) {
              unitObj.heaterBooster = data.heaterBooster;
            }
            if ("filter" in data) {
              unitObj.filter = data.filter;
            }
            if ("message" in data) {
              unitObj.message = data.message;
            }

            getStoreActions().units.updateUnitLocally({
              id: unitId,
              unit: unitObj,
            });
            return;
          }
          if (name === "POWERMETER_UPDATED") {
            if (!isSelectedSite) {
              return;
            }
            const { id, power, lastReadingTimestamp } = data;
            getStoreActions().powerMeters.updatePowerMeterPowerLocally({
              id,
              power,
              lastReadingTimestamp,
            });
            return;
          }

          if (name === "UPDATE_SENSOR") {
            if (!isSelectedSite) {
              return;
            }
            getStoreActions().sensors._storeUpdateSensor({
              id: data.sensorId,
              data: {
                readingValue: data.readingValue,
                readingValueTimestamp: data.readingValueTimestamp,
              },
            });
            return;
          }

          if (name === "PROCEDURE_INSTANCE_UPDATED") {
            const cb = getStoreState().callBacks;
            if (cb[name]) {
              cb[name](data);
            }
            return;
          }

          if (name === "DEVICE_CONNECTED") {
            rootActions.devices._storeUpdateDeviceConnection({
              deviceId: data.deviceId,
              data: { isConnected: true },
            });
            return;
          }

          if (name === "DEVICE_DISCONNECTED") {
            rootActions.devices._storeUpdateDeviceConnection({
              deviceId: data.deviceId,
              data: { isConnected: false },
            });
            return;
          }
          if (name === "UNIT_RECONNECTED") {
            getStoreActions().units.updateUnitLocally({
              id: data.unitId,
              unit: { isConnected: true },
            });
            return;
          }
          if (name === "UNIT_DISCONNECTED") {
            getStoreActions().units.updateUnitLocally({
              id: data.unitId,
              unit: { isConnected: false },
            });
            return;
          }
          if (name === "WS_EVENT_CREATED") {
            const allAlerts = getStoreState().alerts.allAlerts;
            const isExist = isUndefined(allAlerts[message.data.eventId]);
            getStoreActions().alerts.updateNewerAlertsExist({ isExist: isExist });
            return;
          }
          if (name === "WS_EVENT_UPDATED") {
            getStoreActions().alerts.fetchUpdatedAlert({ id: data.eventId });
            return;
          }
        },
        () => actions.repullData()
      );
    }
  ),
  setLoggedIn: action((state, payload) => {
    state.isLoggedIn = payload;
  }),
  setIsUsernameNotEmail: action((state, payload) => {
    state.isUsernameNotEmail = payload;
  }),
  setCurrentEmail: action((state, payload) => {
    state.currentUserEmail = payload;
  }),
  setEnableErrorPage: action((state, payload) => {
    state.enableErrorPage = payload;
  }),
  enableErrorPage: false,
  setLogOut: action((state, payload) => {
    state.selections.selections.customerId = null;
    state.selections.selections.siteId = null;
    state.selections.selections.systemId = null;
    state.selections.selections.unitId = null;
    state.selections.selections.lastSelectedUnitId = null;
    state.isInitialized = false;
  }),
  setUnitUpdateStatus: action((state, payload) => {
    state.unitUpdateStatus = payload.status;
  }),

  fetchedUserTree: action((state, status = true) => {
    state.isInitialized = !!status;
  }),

  setServiceParams: action((state, payload) => {
    state.serviceParams = payload;
  }),
  updateServiceParams: action((state, payload) => {
    state.serviceParams[payload.code] = payload.param;
  }),
  deleteServiceParams: action((state, payload) => {
    delete state.serviceParams[payload];
  }),
  setTypes: action((state, payload) => {
    state.types = payload;
  }),

  setServiceParamTypes: action((state, payload) => {
    state.serviceParamTypes = payload;
  }),
  setServiceErrorTypes: action((state, payload) => {
    state.serviceErrorTypes = payload;
  }),
  resetCallBacks: action(state => {
    state.callBacks = {};
  }),
  setCallBacks: action((state, payload) => {
    state.callBacks[payload.messageName] = payload.func;
  }),
  adminLogin: thunk(async (actions, payload, { injections }) => {
    const { sdkAdmin } = injections;
    const data = await sdkAdmin.getUserToken(payload),
      { token } = data;

    localStorage.setItem("token", token);
    window.location.href = "/dashboard";
  }),
  getObject: computed(
    [
      (state, storeState) => storeState.customers.allCustomers,
      (state, storeState) => storeState.sites.allSites,
      (state, storeState) => storeState.devices.allDevices,
      (state, storeState) => storeState.systems.allSystems,
      (state, storeState) => storeState.units.allUnits,
    ],
    (customers, sites, devices, systems, units) => (objType, objId) => {
      let obj: any = {};

      switch (objType) {
        case "customer":
          obj = customers[objId];
          break;
        case "site":
          obj = sites[objId];
          break;
        case "device":
          obj = devices[objId];
          break;
        case "system":
          obj = systems[objId];
          break;
        case "unit":
          obj = units[objId];
          break;
        default:
          obj = null;
          break;
      }
      return obj;
    }
  ),
  operationModesMirror: computed(state => {
    const { types } = state;
    const { operationModesExtended } = types;

    if (!operationModesExtended) {
      return null;
    }

    const keys = Object.keys(operationModesExtended);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = operationModesExtended[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  operationStatusesMirror: computed(state => {
    const { types } = state;
    const { operationStatuses } = types;

    if (!operationStatuses) {
      return null;
    }

    const keys = Object.keys(operationStatuses);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = operationStatuses[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  fanModesMirror: computed(state => {
    const { types } = state;
    const { fanModesExtended } = types;

    if (!fanModesExtended) {
      return null;
    }

    const keys = Object.keys(fanModesExtended);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = fanModesExtended[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  swingModesMirror: computed(state => {
    const { types } = state;
    const { swingModes } = types;

    if (!swingModes) {
      return null;
    }

    const keys = Object.keys(swingModes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = swingModes[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  temperatureScaleMirror: computed(state => {
    const { types } = state;
    const { temperatureScale } = types;

    if (!temperatureScale) {
      return null;
    }

    const keys = Object.keys(temperatureScale);
    const object: { [index: string]: any } = {};

    const temperatureScaleType = keys.reduce((types, key) => {
      const name = temperatureScale[key];
      types[name as string] = key;

      return types;
    }, object);

    return temperatureScaleType;
  }),
  procedureRunningStatesMirror: computed(state => {
    const { types } = state;
    const { procedureRunningStates } = types;

    if (!procedureRunningStates) {
      return {};
    }

    const keys = Object.keys(procedureRunningStates);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((types, key) => {
      const name = procedureRunningStates[key];
      types[name as string] = key;

      return types;
    }, object);

    return eventType;
  }),
  stepsOptions: computed(state => {
    const { types } = state;
    const { procedureStepTypes } = types;

    if (!procedureStepTypes) {
      return [];
    }

    return [
      { value: procedureStepTypes.command, name: "Action" },
      { value: procedureStepTypes.wait, name: "Wait" },
      { value: procedureStepTypes.condition, name: "Condition Check" },
    ];
  }),
  commandsOptions: computed(state => {
    const { types } = state;
    const { procedureDeviceCommands } = types;

    if (!procedureDeviceCommands) {
      return [];
    }

    return {
      [procedureDeviceCommands.setUnitOperationMode]: {
        value: procedureDeviceCommands.setUnitOperationMode,
        name: "Mode",
        enum: "operationModesExtended",
      },
      [procedureDeviceCommands.setUnitPowerState]: {
        value: procedureDeviceCommands.setUnitPowerState,
        name: "Power",
        enum: "operationStatuses",
      },
      [procedureDeviceCommands.setUnitFanMode]: {
        value: procedureDeviceCommands.setUnitFanMode,
        name: "Fan",
        enum: "fanModes",
      },

      [procedureDeviceCommands.setUnitSetpoint]: {
        value: procedureDeviceCommands.setUnitSetpoint,
        name: "Setpoint",
        data_unit_of_measurement: "°C"
      },
    };
  }),
  checkOperatorsOptions: [
    {
      value: ">",
      label: ">",
    },
    {
      value: "<",
      label: "<",
    },
    { value: "=", label: "=" },
    { value: "threshold", label: "Difference" },
  ],
  thresholdOptions: [
    { value: "threshold", label: ">" },
    { value: "thresholdWithin", label: "<" },
  ],
  enumOperatorsOptions: [
    { value: "=", label: "=" },
    { value: "!=", label: "≠" },
  ],

  operatorTypesMap: {
    ">": ">",
    "<": "<",
    "=": "=",
    "!=": "≠",
    "threshold": ">",
    "thresholdWithin": "<"
  },

  conditionsOptions: computed(state => {
    const { types } = state;
    const { procedureConditions } = types;

    if (!procedureConditions) {
      return [];
    }

    return {
      [procedureConditions.unitOperationMode]: {
        value: procedureConditions.unitOperationMode,
        name: "Mode",
        enum: "operationModesExtended",
      },
      [procedureConditions.unitPowerState]: {
        value: procedureConditions.unitPowerState,
        name: "Power",
        enum: "operationStatuses",
      },
      [procedureConditions.unitFanMode]: {
        value: procedureConditions.unitFanMode,
        name: "Fan",
        enum: "fanModes",
      },
      [procedureConditions.ambientTemperature]: {
        value: procedureConditions.ambientTemperature,
        name: "RoomTemp",
      },
      [procedureConditions.unitSetpoint]: {
        value: procedureConditions.unitSetpoint,
        name: "Setpoint",
      },
    };
  }),
  getFullTree: thunk((actions, payload, { injections }) => {
    const { sdkUser } = injections;
    return sdkUser.getMyTree();
  }),
  repullData: thunk(async (actions, payload, { injections }) => {
    const { isMobile } = require("react-device-detect");
    if (!navigator.onLine) {
      setTimeout(() => actions.repullData(), 20000);
    }

    actions.units.getUnits();
    actions.devices.getDevices();
    actions.sensors.getAllSensors();
    actions.powerMeters.getAllPowerMeters();
  }),
  procedureStatuses: computed(state => {
    const { types } = state;
    const { procedureRunningStates } = types;

    if (!procedureRunningStates) {
      return {};
    }

    return {
      [procedureRunningStates.completedFailure]: t`Completed (with Issues)`,
      [procedureRunningStates.completedPartialSuccess]: t`Completed (with Issues)`,
      [procedureRunningStates.completedSuccess]: t`Completed`,
      [procedureRunningStates.initialized]: t`Initialized`,
      [procedureRunningStates.paused]: t`Paused`,
      [procedureRunningStates.running]: t`Running`,
      [procedureRunningStates.stopped]: t`Stopped`,
    };
  }),
  isDropDownOpen: {
    settings: false,
    commissioning: false,
    users: false,
    diagnostics: false,
    operationAutomate: false,
    powerDistribution: false,
    bacnets: false
  },
  setDropDownNavigation: action((state, payload) => {
    state.isDropDownOpen[payload] = !state.isDropDownOpen[payload];
  }),
  reportResults: computed(state => {
    const { types } = state;
    const { procedureRunResultTypes } = types;

    if (!procedureRunResultTypes) {
      return {};
    }

    return {
      [procedureRunResultTypes.failure]: t`Completed (with Issues)`,
      [procedureRunResultTypes.partialSuccess]: t`Completed (with Issues)`,
      [procedureRunResultTypes.success]: t`Completed`,
      [procedureRunResultTypes.stopped]: t`Stopped`,
    };
  }),
  stepResultStatus: computed(state => {
    const { types } = state;
    const { procedureStepResultTypes } = types;

    if (!procedureStepResultTypes) {
      return {};
    }

    return {
      [procedureStepResultTypes.failure]: t`Failure`,
      [procedureStepResultTypes.notExecuted]: t`Not Executed`,
      [procedureStepResultTypes.success]: t`Success`,
    };
  }),
  stepsTypesMirror: computed(state => {
    const { types } = state;
    const { procedureStepTypes } = types;

    if (!procedureStepTypes) {
      return {};
    }

    const keys = Object.keys(procedureStepTypes);
    const object: { [index: string]: any } = {};

    const eventType = keys.reduce((types, key) => {
      const name = procedureStepTypes[key];
      types[name as string] = key;

      return types;
    }, object);

    return eventType;
  }),
  setThemeFile: action((state, payload) => {
    if (!!state.theme) {
      return;
    }
    state.theme = payload;
  }),
  setConfigFile: action((state, payload) => {
    if (!!state.config) {
      return;
    }
    state.config = payload;
  }),
  setStillLoadingInitialAPIs: action((state, payload) => {
    state.stillLoadingInitialAPIs = payload;
  }),
  prepareTheme: thunk(actions => {
    const themeUrl = `${process.env.PUBLIC_URL
      }/assets/${getHostname()}/style.config.json`;

    fetch(themeUrl)
      .then(res => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then(res => {
        actions.setThemeFile(res);
      })
      .catch(() => {
        return fetch(
          `${process.env.PUBLIC_URL}/assets/localhost/style.config.json`
        )
          .then(res => {
            if (!res.ok) {
              throw new Error("Fallback network response was not ok");
            }
            return res.json();
          })
          .then(res => {
            actions.setThemeFile(res);
          });
      });

    const configFile = `${process.env.PUBLIC_URL
      }/assets/${getHostname()}/config.json`;
    fetch(configFile)
      .then(res => res.json())
      .then(res => {
        actions.setConfigFile(res);
      });

    const favIconUrl = `${process.env.PUBLIC_URL
      }/assets/${getHostname()}/favicon.ico`;
    const favIconEl: any = document.getElementById("favIconId");
    favIconEl.href = favIconUrl;
  }),
  ruleParameters: [
    {
      allBrands: true,
      code: 52,
      data_unit_of_measurement: "",
      enabledInTriggers: false,
      enum: "unitFanModes",
      hvac_param_name: "FanSpeed",
      max: 10,
      min: 0,
      plotable: true,
      showInGraph: true,
      title: "Fan Speed",
    },
    {
      allBrands: true,
      code: 51,
      data_unit_of_measurement: "",
      enabledInTriggers: true,
      enum: "unitOperationModes",
      hvac_param_name: "Mode",
      max: 10,
      min: 0,
      plotable: true,
      showInGraph: true,
      title: "Mode",
    },
    {
      allBrands: true,
      code: 48,
      data_unit_of_measurement: "",
      enabledInTriggers: true,
      enum: "unitOperationStatuses",
      hvac_param_name: "IndoorOnOffStatus",
      max: 1,
      min: 0,
      plotable: true,
      showInGraph: false,
      title: "ON/OFF",
    },
    {
      allBrands: true,
      code: 49,
      data_unit_of_measurement: "°C",
      enabledInTriggers: true,
      hvac_param_name: "RoomTemp",
      max: 0,
      min: 100,
      plotable: true,
      showInGraph: false,
      title: "Room Temp",
    },
    {
      allBrands: true,
      code: 50,
      data_unit_of_measurement: "°C",
      enabledInTriggers: true,
      hvac_param_name: "SetTemp",
      max: 0,
      min: 100,
      plotable: true,
      showInGraph: false,
      title: "Set Temp",
    },
    {
      allBrands: true,
      code: 57,
      data_unit_of_measurement: "",
      enabledInTriggers: false,
      enum: "",
      hvac_param_name: "SiteTemp",
      max: 150,
      min: -50,
      plotable: true,
      showInGraph: true,
      title: "Site Temperature",
    },
  ],
  unitSubTypesMirror: computed(state => {
    const { types } = state;
    const { unitSubTypes } = types;

    if (!unitSubTypes) {
      return null;
    }

    const keys = Object.keys(unitSubTypes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((types, key) => {
      const name = unitSubTypes[key];
      types[name as string] = key;

      return types;
    }, object);

    return modes;
  }),
  onUpdateUnits: actionOn(
    (actions, storeActions) => [
      actions.units.initialize,
      actions.units.updateUnitLocally,
      actions.units.getUnits,
      actions.units.updateServiceUnitsForMultiUnits,
      actions.units.updateLocalSetpoint,
      actions.units._storeUpdateUnit,
      actions.units.getAndReplaceUnits,
    ],
    (state, target) => {
      state.resetPulling = state.resetPulling + 1;
    }
  ),
  customParameterOperationsOptionsObject: computed(state => {
    const { types } = state;
    const { customParameterOperations } = types;

    if (!customParameterOperations) {
      return [];
    }

    return {
      [customParameterOperations["+"]]: {
        value: customParameterOperations["+"],
        name: "+",
      },
      [customParameterOperations["-"]]: {
        value: customParameterOperations["-"],
        name: "-",
      },
      [customParameterOperations["*"]]: {
        value: customParameterOperations["*"],
        name: "*",
      },
      [customParameterOperations["/"]]: {
        value: customParameterOperations["/"],
        name: "/",
      },
    };
  }),
  customEnumOptionsForAutomationRules: computed(
    [state => state.types],
    types => {
      const {
        unitSubTypes = {},
        fanModesExtended = {},
        operationModesExtended = {},
        subTypesFanModes = {},
        subTypesOperationModes = {},
        defaultOperationModes = [],
      } = types;
      const modesOptions: any = {
        systems: {
          unitFanModes: [],
          unitOperationModes: [],
        },
        indoors: {},
      };
      modesOptions.systems.unitFanModes = Object.keys(fanModesExtended).map(
        (key: any) => ({ name: fanModesExtended[key], value: key + "" })
      );
      modesOptions.systems.unitOperationModes = defaultOperationModes.map(
        (key: any) => ({
          name: operationModesExtended[key],
          value: key + "",
        })
      );
      Object.keys(unitSubTypes).forEach((subType: any) => {
        modesOptions.indoors[subType] = {
          unitFanModes: [],
          unitOperationModes: [],
        };
        modesOptions.indoors[subType].unitFanModes = (
          subTypesFanModes[subType] || []
        ).map((key: number) => ({
          name: fanModesExtended[key],
          value: key + "",
        }));
        modesOptions.indoors[subType].unitOperationModes = (
          subTypesOperationModes[subType] || []
        ).map((key: number) => ({
          name: operationModesExtended[key],
          value: key + "",
        }));
      });
      return modesOptions;
    }
  ),
  getTypes: thunk(async (actions, payload, { injections }) => {
    const { sdkService } = injections;
    const types = await sdkService.getTypes();
    actions.setTypes(types);
    return types;
  }),
  getServiceParams: thunk(async (actions, payload, { injections }) => {
    const { sdkService } = injections;
    const params = await sdkService.getServiceParams();
    actions.setServiceParams(params || {});
    return params;
  }),
  getDevicesTypesNames: computed(
    [state => state.types],
    types =>
      (deviceType, deviceBrand = "") => {
        const { deviceTypes } = types;
        if (!deviceTypes) {
          return "";
        }

        if (deviceType === 1) {
          const device1brandNames: any = {
            0: "CoolMasterNet",
            1: "Cloud to Cloud integration",
            2: "Cloud to Cloud integration",
            3: "CoolMaster Edge",
          };

          return device1brandNames[deviceBrand] || "";
        } else {
          return deviceTypes[deviceType]?.name || "";
        }
      }
  ),
  getEntityNameById: computed(state => entityId => {
    if (!entityId) {
      return "";
    }
    if (state.customers.allCustomers[entityId]) {
      return state.customers.allCustomers[entityId]?.name;
    }
    if (state.sites.allSites[entityId]) {
      return state.sites.allSites[entityId]?.name;
    }
    if (state.devices.allDevices[entityId]) {
      return state.devices.allDevices[entityId]?.name;
    }
    if (state.units.allUnits[entityId]) {
      return state.units.allUnits[entityId]?.name;
    }
    if (state.groups.allGroups[entityId]) {
      return state.groups.allGroups[entityId]?.name;
    }
    return "";
  }),
};

const typedHooks = createTypedHooks<IRootStoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export default RootStoreModel;
