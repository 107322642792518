import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: 'fit-content',
    maxWidth: '95% !important',
    minWidth: '600px',
    height: 'fit-content',
    maxHeight: 'calc(100vh - 140px)',
    minHeight: '300px',
    borderRadius: '8px',
    boxShadow: '0 2px 11px 0 rgba(0, 0, 0, 0.15)',
    border: 'solid 1px #d5d2d5',
    backgroundColor: '#ffffff',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  dialogHeader: {
    paddingLeft: 20,
    paddingRight: 13,
    ...theme.dialogs.header,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    minHeight: 60,
    background: '#D5D2D5',
    borderBottomColor: '#D5D2D5',
  },
  headerTitle: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '21.09px',
  },
  dialogContent: {
    padding: '50px 60px',
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: 1,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    width: '100%',
  },
  actionsContainer: {
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '24px',
    width: '100%',
  },
  iconBtnStyle: {
    width: 30,
    height: 30,
    borderRadius: 6,
    padding: 0,
    marginLeft: 10,
  },
  label: {
    fontSize: '16px',
    lineHeight: '18.75px',
    color: '#7F7182',
    marginBottom: '8px',
  },
  inputRoot: {
    padding: 0,
    '& input': {
      padding: '12px 16px',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '18px',
      color: '#545964',
    },
  },
  selectRoot: {
    width: '175px',
  },
}));

export default useStyles;
