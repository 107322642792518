import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import AddRule from "../../components/AddRule";
import Delete from "../../components/Delete/Delete";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import ServiceNavigationBar from "../../components/Menu/ServiceNavigationBar";
import LightTooltip from "../../components/Tooltip/LightTooltip";
import Button from "../../cool_widgets/Button";
import { EditIcon } from "../../logos";
import { useStoreActions, useStoreState } from "../../models/RootStore";
import useStyles from "./AnomalyTemplatesList.style";

const AnomalyTemplatesList: React.FC = (props: any) => {
    const classes = useStyles();

    const isInitialized = useStoreState((s) => s.isInitialized);
    const getTrapTemplates = useStoreActions((action) => action.anomalyTemplates.getTrapTemplates);
    const addTrapTemplate = useStoreActions((action) => action.anomalyTemplates.addTrapTemplate);
    const updateTrapTemplate = useStoreActions((action) => action.anomalyTemplates.updateTrapTemplate);
    const deleteTrapTemplate = useStoreActions((action) => action.anomalyTemplates.deleteTrapTemplate);

    const [openAddRule, setOpenAddRule] = useState<boolean>(false);
    const [anomalyTemplates, setAnomalyTemplates] = useState<any>({});
    const [editTrapTemplate, setEditTrapTemplate] = useState<any>(null);

    useEffect(() => {
        getTrapTemplates().then((response: any) => setAnomalyTemplates(response)).catch((error: any) => { console.error("add error box", error); });
    }, []);

    const addNewTrapTemplate = (data: any, customerId: string) => {
        return addTrapTemplate(data)
            .then((resp: any) => {
                setAnomalyTemplates({ ...anomalyTemplates, [resp.id]: { ...resp } });
            })
            .catch((error: any) => {

            });
    };

    const updateExistingTrapTemplate = (data: any, trapId: string) => {
        return updateTrapTemplate({ data, trapId })
            .then((resp: any) => {
                setAnomalyTemplates({ ...anomalyTemplates, [trapId]: { ...anomalyTemplates[trapId], ...data } });
            })
            .catch((error: any) => {

            });
    };

    const closeDialog = () => {
        setOpenAddRule(false);
        setEditTrapTemplate(null);
    };

    if (!isInitialized) { return <Loading />; }

    return (
        <div className={classes.view}>
            <ServiceNavigationBar {...props} />
            <div className={classes.contentArea}>
                <Header hideSiteSelection hideSystemSelection hideUnitSelection />
                <div className={classes.titleBar}>
                    <Button
                        onClick={() => setOpenAddRule(true)}
                    >
                        {t`Add Template`}
                    </Button>
                </div>
                <Paper elevation={0} className={classes.paperTableContainer}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`Template Name`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`Description`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`EDIT`}</TableCell>
                                    <TableCell
                                        classes={{ root: classes.tableHeadCell }}
                                        align="left"
                                    >{t`REMOVE`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(anomalyTemplates).map((trap: any) => (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={trap.id}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            classes={{ root: clsx(classes.overWritePadding, classes.smallWidth) }}
                                            align="left"
                                        >
                                            {trap.name}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            classes={{ root: clsx(classes.overWritePadding, classes.mediumWidth) }}
                                            align="left"
                                        >
                                            {trap.description}
                                        </TableCell>
                                        <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                            <LightTooltip title={t`Edit anomaly`}>
                                                <IconButton
                                                    className={classes.editIcon}
                                                    onClick={() => setEditTrapTemplate(trap)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </LightTooltip>
                                        </TableCell>
                                        <TableCell classes={{ root: classes.overWritePadding }} align="left">
                                            <Delete
                                                type={t`anomaly`}
                                                object={trap}
                                                detach={deleteTrapTemplate}
                                                getActionResult={(result: any) => {
                                                    if (result) {
                                                        delete anomalyTemplates[trap.id];
                                                        setAnomalyTemplates({ ...anomalyTemplates });
                                                    }
                                                }}
                                                buttonClass={classes.deleteIcon}
                                            ></Delete>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                {(openAddRule || editTrapTemplate) &&
                    <AddRule
                        close={closeDialog}
                        createRule={addNewTrapTemplate}
                        editTrap={editTrapTemplate}
                        updateRule={updateExistingTrapTemplate}
                        template
                    />
                }
            </div>
        </div>
    );
};

export default AnomalyTemplatesList;
