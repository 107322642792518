import React from 'react';
import clsx from 'clsx';
import { Close as CloseIcon } from '@material-ui/icons';
import { isEmpty } from 'lodash';

import { labelSizes } from '../../constants';
import OnOff from '../Controls/OnOff';
import Toggle from '../Controls/Toggle';
import ListControl from '../Controls/List';
import InformationText from '../Controls/InformationText';
import NumericValue from '../Controls/NumericValue';
import useStyles from './styles';

const DraggableDataPoint = ({
  dataPoint,
  isSelected,
  onDelete,
  mode,
  onChangeControlValue,
  controlValue,
}) => {
  const classes = useStyles();
  const showDelete = mode === 'edit';
  const isControlDisabled =
    mode === 'edit' || (mode === 'control' && dataPoint?.config?.readOnly);

  const renderControlType = (controlType) => {
    if (controlType === 'informationText') {
      const formatValue = () => {
        if (mode === 'edit') {
          return '1';
        }
        
        const numValue = Number(controlValue);
        if (isNaN(numValue)) {
          return controlValue;
        }
        
        return Number.isInteger(numValue) 
          ? String(numValue) 
          : numValue.toFixed(1);
      };

      const value = formatValue();

      return (
        <InformationText
          value={value}
          isDisabled={isControlDisabled}
          style={{
            fontSize: labelSizes[dataPoint.labelSize],
            color: dataPoint.labelColorConfig,
          }}
        />
      );
    }

    if (controlType === 'toggle') {
      return (
        <Toggle
          onChange={(newVal) => {
            !isControlDisabled && onChangeControlValue?.(newVal);
          }}
          value={mode === 'edit' ? true : controlValue}
          isDisabled={isControlDisabled}
        />
      );
    }

    if (controlType === 'onOff') {
      return (
        <OnOff
          onChange={(newVal) => {
            !isControlDisabled && onChangeControlValue?.(newVal);
          }}
          value={mode === 'edit' ? true : controlValue}
          isDisabled={isControlDisabled}
        />
      );
    }

    if (controlType === 'list') {
      let options = [];
      if (!isEmpty(dataPoint?.config?.customEnum)) {
        const customEnum = dataPoint?.config?.customEnum;
        options = Object.keys(customEnum).map((enumKey) => {
          return {
            value: enumKey,
            name: customEnum[enumKey],
          };
        });
      }

      return (
        <ListControl
          mode={mode}
          onChange={(newVal) => {
            !isControlDisabled && onChangeControlValue?.(newVal);
          }}
          value={mode === 'edit' ? null : controlValue}
          isDisabled={isControlDisabled}
          options={options}
        />
      );
    }

    if (controlType === 'numericValue') {
      return (
        <NumericValue
          value={mode === 'edit' ? 1 : controlValue}
          max={dataPoint?.config?.customEnum?.max}
          min={dataPoint?.config?.customEnum?.min}
          step={dataPoint?.config?.customEnum?.step}
          onChange={(newVal) => {
            !isControlDisabled && onChangeControlValue?.(newVal);
          }}
          isDisabled={isControlDisabled}
        />
      );
    }

    return 'Undefined Control Type';
  };

  return (
    <div
      className={clsx(classes.draggableDataPoint, {
        [classes.labelTop]: dataPoint.labelPosition === 'top',
        [classes.labelBottom]: dataPoint.labelPosition === 'bottom',
        [classes.labelLeft]: dataPoint.labelPosition === 'left',
        [classes.labelRight]: dataPoint.labelPosition === 'right',
        isSelected: isSelected,
      })}
    >
      <div
        className={classes.draggableDataPoint__label}
        style={{
          fontSize: labelSizes[dataPoint.labelSize],
          color: dataPoint.labelColorConfig,
        }}
      >
        {dataPoint.label}
      </div>
      <div className={classes.draggableDataPoint__control}>
        {renderControlType(dataPoint?.controlType)}
      </div>
      {showDelete && (
        <div
          className={classes.draggableDataPoint__CloseBtn}
          onClick={onDelete}
        >
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

export default DraggableDataPoint;
