import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { useStoreState } from "../../models/RootStore";
import {
  AutoMode,
  AutoVRMode,
  ColdIcon,
  DryIcon,
  Fan1Icon,
  Fan2Icon,
  Fan3Icon,
  Fan4Icon,
  FanAutoIcon,
  FanMode,
  Hot,
  Swing,
  Swing30,
  Swing45,
  Swing60,
  SwingHor,
  SwingOff2,
  SwingVer
} from "../../svgComponents";
import modesListStyle from "./modesList.style";

const days: any = {
  Friday: "Fri",
  Monday: "Mon",
  Saturday: "Sat",
  Sunday: "Sun",
  Thursday: "Thu",
  Tuesday: "Tue",
  Wednesday: "Wed"
};

const ModesList = (props: any) => {
  const { activeModes = [], action, customClass, modeName, disabled = false, showOnlySelected = false } = props;
  const useStyle = makeStyles(modesListStyle);
  const classes = useStyle();
  const { container, modeStyle, activeModeStyle, nameStyle } = classes;
  const types = useStoreState((states) => states.types);

  const icons: any = {
    operationModesExtended: {
      COOL: <ColdIcon />,
      HEAT: <Hot />,
      AUTO: <AutoMode />,
      "Virtual Auto": <AutoVRMode />,
      DRY: <DryIcon />,
      FAN: <FanMode />
    },
    fanModes: {
      LOW: <Fan1Icon />,
      MEDIUM: <Fan2Icon />,
      HIGH: <Fan3Icon />,
      AUTO: <FanAutoIcon />,
      VERYLOW: <Typography>{t`Very Low`}</Typography>,
      TOP: <Fan4Icon />
    },
    swingModes: {
      vertical: <SwingVer color1="#4b1c46" color2="#4b1c46" />,
      horizontal: <SwingHor color1="#4b1c46" color2="#4b1c46" />,
      30: <Swing30 color1="#4b1c46" color2="#4b1c46" />,
      45: <Swing45 color1="#4b1c46" color2="#4b1c46" />,
      60: <Swing60 color1="#4b1c46" color2="#4b1c46" />,
      off: <SwingOff2 color1="#4b1c46" color2="#4b1c46" />,
      auto: <Swing color1="#4b1c46" color2="#4b1c46" />
    },
    weekDays: {}
  };

  let mode = types[modeName as any];
  if (!mode) {
    return <div>{t`pending...`}</div>;
  }

  if (modeName === "swingModes") {
    _.forEach(mode, (value, key) => {
      if (value === "on" || value === "off") {
        delete mode[key];
      }
    });
  }

  let supportedValuesArr: any = modeName !== "operationModesExtended"
    ? Object.keys(mode)
    : Object.keys(mode).reverse();

  if (modeName === "fanModes") {
    const autoValueAsNumber = Object.keys(mode).find((key) => mode[key] === "AUTO");
    const autoIndex = supportedValuesArr.findIndex((item: any) => item === autoValueAsNumber);
    supportedValuesArr.splice(autoIndex, 1);
    supportedValuesArr.push(autoValueAsNumber);
  }

  if (modeName === "swingModes") {
    const verticalValueAsNumber = Object.keys(mode).find((key) => mode[key] === "vertical");
    const horizontalValueAsNumber = Object.keys(mode).find((key) => mode[key] === "horizontal");
    const horizontalIndex = supportedValuesArr.findIndex((item: any) => item === horizontalValueAsNumber);
    supportedValuesArr.splice(0, 1, horizontalValueAsNumber);
    supportedValuesArr.splice(horizontalIndex, 1, verticalValueAsNumber);
  }

  return (
    <div className={container}>
      {supportedValuesArr.map((modeKey: any, index: number) => {
        const stateName = mode[modeKey];
        const isSelected = modeName === "weekDays" ? activeModes.indexOf(modeKey) > -1 : activeModes.indexOf(+modeKey) > -1;

        const modeIcon = icons[modeName][stateName] || (
          <Typography className={nameStyle}>{modeName === "weekDays" ? days[stateName] : stateName}</Typography>
        );

        return (
          <div
            className={clsx(
              modeStyle,
              {
                [activeModeStyle]: !isSelected,
                [classes.swingStyleIcon]: modeName === "swingModes",
                [classes.fanStyleIcon]: modeName === "fanModes",
                [classes.operationModes]: modeName === "operationModesExtended",
                [classes.operationModesCool]: modeName === "operationModesExtended" && stateName === "COOL",
                [classes.operationModesHeat]: modeName === "operationModesExtended" && stateName === "HEAT",
                [customClass]: isSelected,
                [classes.daysBtns]: modeName === "weekDays",
                [classes.hidden]: showOnlySelected && !isSelected
              }

            )}
            key={index}
            onClick={() => disabled ? {} : action(modeName, modeKey)}
          >
            {
              React.cloneElement(modeIcon, {
                opacity: isSelected ? 1 : 0.4
              })}

          </div>
        );
      })}
    </div>
  );
};

export default ModesList;
