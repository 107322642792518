import React, { useState, useCallback, useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';
import { t as translate } from 'ttag';

import ButtonV2 from '@cool_widgets/ButtonV2';
import useStyles from './styles';

const ConfirmationDialog = ({
  open,
  onClose,
  title = translate`Confirm?`,
  bodyMessage = translate`Are you sure to continue with the current action?`,
  confirmText = translate`Confirm`,
  cancelText = translate`Cancel`,
}) => {
  const classes = useStyles();

  const handleConfirm = () => {
    onClose(true);
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth={false}>
      <DialogTitle className={classes.dialogTitle}>
        {title}
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          className={classes.closeButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>{bodyMessage}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {cancelText && (
          <ButtonV2 onClick={handleCancel} variant="outlined">
            {cancelText}
          </ButtonV2>
        )}
        <ButtonV2 onClick={handleConfirm} variant="contained">
          {confirmText}
        </ButtonV2>
      </DialogActions>
    </Dialog>
  );
};

const useConfirmationDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const resolveRef = useRef();
  const [dialogProps, setDialogProps] = useState({});

  const showDialog = useCallback((customProps = {}) => {
    return new Promise((resolve) => {
      resolveRef.current = resolve;
      setDialogProps(customProps);

      setIsOpen(true);
    });
  }, []);

  const handleClose = useCallback((result) => {
    setIsOpen(false);
    resolveRef.current?.(result);
  }, []);

  const DialogComponent = useCallback(
    () => (
      <ConfirmationDialog
        open={isOpen}
        onClose={handleClose}
        {...dialogProps}
      />
    ),
    [isOpen, handleClose]
  );

  return [showDialog, DialogComponent];
};

export default useConfirmationDialog;
