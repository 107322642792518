import { bacnetObjectTypeShortNameToColor, bacnetTypeIdToShortName } from '@constants/bacnet';
import useTypes from '@hooks/useTypes';
import { useStoreActions } from '@models/RootStore';
import { smartConvertToStringFormats } from '@utils/StringUtils';
import { useState, useRef, useMemo } from 'react';


const useBacNet = () => {
  const {
    getSiteBacnetParameterTemplates,
    getBacnetUnitObjects,
    getBacnetUnitById,
    createBacnetParameterTemplate,
    updateBacnetParameterTemplate,
    getSiteBacnetUnits,
    assignBacnetParameterTemplate,
    unassignBacnetParameterTemplate,
    updateBacnetUnit,
    assignBacnetUiTemplate,
    unassignBacnetUiTemplate,
    deleteBacnetUnit,
    discoverBacnetUnitsByDevice: handleDiscoverBacnetUnitsByDevice,
    discoverBacnetUnitsBySite: handleDiscoverBacnetUnitsBySite,
    discoverBacnetDeviceObjects: handleDiscoverBacnetDeviceObjects,
  } = useStoreActions((action) => action.bacnetDevices);

  const {bacnetObjectTypes} = useTypes();

  /**
 * Memoized mapping of BACnet object type IDs to capitalized names.
 * @type {Object<string, string>}
 */
  const capitalizedNamesByObjectTypeId = useMemo(() => {
    if (!bacnetObjectTypes){
      return {}; // Return empty object if bacnetObjectTypes is undefined
    }
    return Object.entries(bacnetObjectTypes).reduce((newNamesObject, [key, value]) => {
      newNamesObject[key] = smartConvertToStringFormats(value).capitalized;
      return newNamesObject;
    }, {});
  }, [bacnetObjectTypes]);



  const { addMessage } = useStoreActions((action) => action.errorMessage);

  // State for tracking BacNet parameter templates
  const [siteBacNetParameterTemplates, setSiteBacNetParameterTemplates] =
    useState({});
  const [siteBacNetUnits, setSiteBacNetUnits] = useState({});

  // State for tracking multiple loading statuses
  const isLoadingRef = useRef({});

  // Using useRef to track loading states
  const withLoader = async (key, asyncFn) => {
    if (isLoadingRef.current[key]) {
      return;
    }
    isLoadingRef.current[key] = true;
    try {
      const result = await asyncFn();
      return result;
    } catch (error) {
      addMessage({ message: error.message || 'An error occurred' });
    } finally {
      isLoadingRef.current[key] = false;
    }
  };

  // Fetch Site BacNet Parameter Templates and store the result with a specific loader key
  const getSiteBacNetUnits = async (siteId) => {
    return withLoader('getSiteBacNetUnits', async () => {
      const res = await getSiteBacnetUnits(siteId);
      setSiteBacNetUnits(res);
      return res;
    });
  };

  // Fetch BacNet Unit by ID with a specific loader key
  const getBacNetUnitById = async (unitId) => {
    return withLoader('getBacNetUnitById', async () => await getBacnetUnitById(unitId));
  };

  // Delete BacNet Unit by ID with a specific loader key
  const deleteBacNetUnit = async (unitId) => {
    return withLoader('deleteBacNetUnit', async () => await deleteBacnetUnit(unitId));
  };

  // Fetch BacNet Unit objects with a specific loader key
  const getBacNetUnitObjects = async (bacnetUnitId) => {
    return withLoader('getBacNetUnitObjects', () =>
      getBacnetUnitObjects(bacnetUnitId)
    );
  };

  // Fetch Site BacNet Parameter Templates and store the result with a specific loader key
  const getSiteBacNetParameterTemplates = async (siteId) => {
    return withLoader('getSiteBacNetParameterTemplates', async () => {
      const templates = await getSiteBacnetParameterTemplates(siteId);
      setSiteBacNetParameterTemplates(templates);
      return templates;
    });
  };

  // Create BacNet Parameter Template with a specific loader key
  const createBacNetParameterTemplate = async (template) => {
    return withLoader('createBacNetParameterTemplate', async () => {
      const createdTemplate = await createBacnetParameterTemplate(template);
      setSiteBacNetParameterTemplates({
        ...siteBacNetParameterTemplates,
        [createdTemplate.id]: createdTemplate,
      });
      return createdTemplate;
    });
  };

  // Create BacNet Parameter Template with a specific loader key
  const createMultipleBacNetParameterTemplates = async (templateDetailsArray = []) => {
    return withLoader('createMultipleBacNetParameterTemplates', () => {

      if(!templateDetailsArray.length){
        return [];
      }

      const promises = templateDetailsArray.map(({newTemplateDetails})=>{
        return createBacnetParameterTemplate(newTemplateDetails);
      });

      return Promise.allSettled(promises).then((allResult)=>{

        const list = [];
        const object = {};

        allResult.forEach(({status,value},index) => {
          if(status === 'fulfilled'){
            list.push({...templateDetailsArray[index],fullTemplate:value});
            object[value.id] = value;
          }
        });
        setSiteBacNetParameterTemplates({
          ...siteBacNetParameterTemplates,
          ...object
        });
        return list;
      });
    });
  };

  // Update BacNet Parameter Template with a specific loader key
  const updateBacNetParameterTemplate = async (data) => {
    return withLoader('updateBacNetParameterTemplate', async () => {
      const updatedTemplate = await updateBacnetParameterTemplate(data);
      setSiteBacNetParameterTemplates((prevState) => ({
        ...prevState,
        [updatedTemplate.id]: updatedTemplate,
      }));
      return updatedTemplate;
    });
  };

  const updateMultipleBacNetParameterTemplates = async (dataArray) => {
    return withLoader('updateBacNetParameterTemplate', async () => {

      const promises = dataArray.map((data)=>{
        return updateBacnetParameterTemplate(data);
      });

      return Promise.allSettled(promises).then((allResult)=>{

        const list = [];
        const object = {};

        allResult.forEach(({status,value}) => {
          if(status === 'fulfilled'){
            list.push(value);
            object[value.id] = value;
          }
        });

        setSiteBacNetParameterTemplates((prevState) => ({
          ...prevState,
          ...object,
        }));

        return list;
      });
    });
  };

  // Assign BacNet Parameter Template with a specific loader key
  const assignBacNetParameterTemplate = async (templateId, bacnetUnitId) => {
    return withLoader('assignBacNetParameterTemplate', async () => {
      await assignBacnetParameterTemplate({ templateId, bacnetUnitId });
      setSiteBacNetUnits((prevState) => ({
        ...prevState,
        [bacnetUnitId]: {
          ...prevState[bacnetUnitId],
          paramTemplate: templateId,
          uiTemplate: null,
        },
      }));
    });
  };

  // Unassign BacNet Parameter Template with a specific loader key
  const unassignBacNetParameterTemplate = async (templateId, bacnetUnitId) => {
    return withLoader('unassignBacNetParameterTemplate', async () => {
      await unassignBacnetParameterTemplate({ templateId, bacnetUnitId });
      setSiteBacNetUnits((prevState) => ({
        ...prevState,
        [bacnetUnitId]: {
          ...prevState[bacnetUnitId],
          paramTemplate: null,
          uiTemplate: null,
        },
      }));
    });
  };

  // Assign BacNet UI Template with a specific loader key
  const assignBacNetUiTemplate = async (templateId, bacnetUnitId) => {
    return withLoader('assignBacNetUiTemplate', async () => {
      await assignBacnetUiTemplate({ templateId, bacnetUnitId });
      setSiteBacNetUnits((prevState) => ({
        ...prevState,
        [bacnetUnitId]: {
          ...prevState[bacnetUnitId],
          uiTemplate: templateId,
        },
      }));
    });
  };

  // Unassign BacNet UI Template with a specific loader key
  const unassignBacNetUiTemplate = async (templateId, bacnetUnitId) => {
    return withLoader('unassignBacNetUiTemplate', async () => {
      await unassignBacnetUiTemplate({ templateId, bacnetUnitId });
      setSiteBacNetUnits((prevState) => ({
        ...prevState,
        [bacnetUnitId]: {
          ...prevState[bacnetUnitId],
          uiTemplate: null,
        },
      }));
    });
  };

  // Update BacNet Unit with a specific loader key
  const updateBacNetUnit = async (data) => {
    return withLoader('updateBacNetUnit', async () => {
      const updatedUnit = await updateBacnetUnit(data);
      setSiteBacNetUnits((prevState) => ({
        ...prevState,
        [data.id]: {
          ...prevState[data.id],
          ...data.data,
        },
      }));
      return updatedUnit;
    });
  };

  const discoverBacnetUnitsByDevice = async (deviceId, line) => {
    return withLoader('discoverBacnetUnitsByDevice', async () => await handleDiscoverBacnetUnitsByDevice({
      deviceId,
      line
    }));
  };

  const discoverBacnetUnitsBySite = async (siteId) => {
    return withLoader('discoverBacnetUnitsBySite', async () => await handleDiscoverBacnetUnitsBySite({
      siteId
    }));
  };

  const discoverBacnetDeviceObjects = async (bacnetDeviceId) => {
    return withLoader('discoverBacnetDeviceObjects', async () => await handleDiscoverBacnetDeviceObjects({
      bacnetDeviceId
    }));
  };

  /**
 * Returns the color, name, and short name associated with a given BACnet type ID.
 * - If the type ID is found in `bacnetTypeIdToShortName`, it returns the corresponding short name and mapped color.
 * - If the short name is not in `bacnetObjectTypeShortNameToColor`, defaults to "magenta".
 * - If the type ID is not in `bacnetTypeIdToShortName`, defaults the short name to the type ID string.
 * - The `name` is retrieved from `capitalizedNamesByObjectTypeId` based on the type ID, or defaults to the short name.
 *
 * @param {number} typeId - The BACnet type ID to look up.
 * @returns {{ color: string, shortName: string, name: string }} - An object containing the color, short name, and name for the type ID.
 */
  const getBacnetIconInformation = (typeId) => {
    const shortName = bacnetTypeIdToShortName[typeId] || typeId.toString(); // Fallback to typeId as string if not found
    const name = capitalizedNamesByObjectTypeId[typeId] || shortName;
    const color = bacnetObjectTypeShortNameToColor[shortName] || 'magenta'; // Default to magenta if color not found

    return { color, shortName, name };
  };

  return {
    isLoading: isLoadingRef.current,
    siteBacNetParameterTemplates,
    getBacNetUnitById,
    getBacNetUnitObjects,
    getSiteBacNetParameterTemplates,
    createBacNetParameterTemplate,
    updateBacNetParameterTemplate,
    getSiteBacNetUnits,
    siteBacNetUnits,
    assignBacNetParameterTemplate,
    unassignBacNetParameterTemplate,
    updateBacNetUnit,
    assignBacNetUiTemplate,
    unassignBacNetUiTemplate,
    getBacnetIconInformation,
    deleteBacNetUnit,
    createMultipleBacNetParameterTemplates,
    updateMultipleBacNetParameterTemplates,
    discoverBacnetUnitsByDevice,
    discoverBacnetUnitsBySite,
    discoverBacnetDeviceObjects
  };
};

export default useBacNet;
